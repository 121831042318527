import { CancelToken } from 'axios';

import BaseRequestService from './RequestService';

class AssetsRequestService extends BaseRequestService {
  async getAllMetadata(cancelToken?: CancelToken): Promise<any[]> {
    const response = await this.get('metadata', { cancelToken });
    return response?.data;
  }

  async getContent(assetId: string, cancelToken?: CancelToken): Promise<any> {
    const response = await this.get(`content/${assetId}`, { cancelToken });
    return response?.data;
  }
}

export const assetsRequestService = new AssetsRequestService();

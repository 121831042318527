enum LabSystemDetailsGeneralAccordionKeys {
  ESSENTIALS = 'essentials',
}

type LabSystemDetailsGeneralVMType = {
  // VM Type
};

type LabSystemDetailsGeneralVCType = {
  // VC Type
};

export { LabSystemDetailsGeneralAccordionKeys, LabSystemDetailsGeneralVCType, LabSystemDetailsGeneralVMType };

import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { CheckboxVisibility, DefaultButton, DetailsRow, IconButton, Modal, SelectionMode } from '@fluentui/react';
import { IDetailsRowProps } from '@fluentui/react/lib/DetailsList';
import MonacoEditor from '@monaco-editor/react';
import { t } from 'i18next';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import AssetTreeViewerViewController from '@/components/AssetTreeViewer/AssetTreeViewerViewController';
import AssetTreeViewerViewModel from '@/components/AssetTreeViewer/AssetTreeViewerViewModel';
import { monacoEditorOptions } from '@/components/ExperimentEditor/ExperimentEditor.config';
import { ExperimentEditorVCType } from '@/components/ExperimentEditorBeta/ExperimentEditorBetaTypes';
import MachineSelectViewController from '@/components/MachineSelect/MachineSelectViewController';
import MachineSelectViewModel from '@/components/MachineSelect/MachineSelectViewModel';
import PreviewExperimentViewController from '@/components/PreviewExperiment/PreviewExperimentViewController';
import PreviewExperimentViewModel from '@/components/PreviewExperiment/PreviewExperimentViewModel';
import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons } from '@/constants/LabsConstants';
import { Namespaces as NS, ReadingPaneStateKeys, SplitPanelDirectionType } from '@/constants/SystemConstants';
import AIRExperimentPublishViewController from '@/partials/_air/ExperimentPublish/ExperimentPublishTemplateViewController';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import '@/components/ExperimentEditor/ExperimentEditor.css';
import styles from '@/components/ExperimentEditor/ExperimentEditor.module.css';

// NavigationMenuViewController : a sub ViewController for the File navigation menu above the experiment editor.
const ExperimentEditorBetaTemplateFC: React.FC<ExperimentEditorVCType> = (props: ExperimentEditorVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { isPartnerMode } = appSettingsStore;
  const { addGlobalMessage, globalMessages } = systemMessageStore;

  const {
    farItems,
    experimentName,
    filterItems,
    hideConfirmModal,
    hidePublish,
    hidePreview,
    labId,
    labToggle,
    loadingExperiment,
    isConfirmModalOpen,
    splitPanelConfig,
    confirmedAction,
    editorMount,
    handleEditorChange,
    loadTemplate,
    toggleHidePublish,
    toggleHidePreview,
  } = props;

  const assetTreeViewerViewModel = new AssetTreeViewerViewModel(rootStore, loadTemplate);
  const machineSelectViewModel = new MachineSelectViewModel(rootStore);
  const previewExperimentViewModel = new PreviewExperimentViewModel(rootStore);
  const confirmationText = t('override-confirmation', { ns: NS.EDITOR });

  return (
    <>
      <div className="fullscreen ee-py4">
        <div className="fullscreen ee-indent">
          <SplitPanel config={splitPanelConfig} index={0} isAllowResize={false} split={SplitPanelDirectionType.VERTICAL}>
            <div className={styles['primary-pane']}>
              <PageCommandBar items={farItems} />
              <PageDivider />
              <AssetTreeViewerViewController viewModel={assetTreeViewerViewModel} />
            </div>
            <div className={styles['reading-pane']}>
              <PageCommandBar items={experimentName} farItems={filterItems} />
              <PageDivider />
              {loadingExperiment && <LoadingSpinner />}
              <MonacoEditor
                height="100%"
                defaultLanguage="json"
                onMount={editorMount}
                onChange={handleEditorChange}
                options={monacoEditorOptions}
              />
            </div>
          </SplitPanel>
          <DialogBox
            title={t('queue-experiment', { ns: NS.EDITOR })}
            subtext={''}
            minWidth={'90%'}
            body={<MachineSelectViewController viewModel={machineSelectViewModel} labId={labId} closeModal={toggleHidePublish} />}
            hidden={!(labToggle && !hidePublish)}
            modalProps={{
              isBlocking: true,
            }}
            onDismiss={toggleHidePublish}
          />
          <DialogBox
            title={t('queue-experiment', { ns: NS.EDITOR })}
            subtext={''}
            minWidth={'90%'}
            body={<AIRExperimentPublishViewController />}
            footer={<DefaultButton onClick={toggleHidePublish} text={t('close', { ns: NS.COMMON })} />}
            hidden={!(!labToggle && !hidePublish)}
            modalProps={{
              isBlocking: true,
            }}
            onDismiss={toggleHidePublish}
          />
          <DialogBox
            title={t('preview-experiment', { ns: NS.EDITOR })}
            subtext={''}
            minWidth={'90%'}
            body={
              <PreviewExperimentViewController
                viewModel={previewExperimentViewModel}
                closeModal={toggleHidePreview}
                toggleHidePublish={toggleHidePublish}
              />
            }
            hidden={!(labToggle && !hidePreview)}
            modalProps={{
              isBlocking: true,
            }}
            onDismiss={toggleHidePreview}
          />
        </div>
      </div>
      {isConfirmModalOpen && (
        <Modal
          className="labs-wrapper"
          titleAriaId="editor-confirmation"
          isOpen={isConfirmModalOpen}
          isBlocking={false}
          containerClassName={contentStyles.container}
          onDismiss={hideConfirmModal}
        >
          <div className={contentStyles.header}>
            <IconButton
              styles={iconButtonStyles}
              iconProps={{ iconName: SystemIcons.CANCEL }}
              ariaLabel={AreaLabels.closePopupModal}
              onClick={isPartnerMode}
            />
          </div>
          <div className={contentStyles.body}>
            <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
            <h5 className={styles['confirm-text']}>{confirmationText}</h5>
            <br />
            <div className="text-center">
              <DefaultButton
                className={`${styles['button']} bg-danger light-text`}
                onClick={() => {
                  confirmedAction();
                }}
              >
                {Buttons.yes}
              </DefaultButton>
              <DefaultButton classStyles={styles['button']} onClick={hideConfirmModal}>
                {Buttons.cancel}
              </DefaultButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const ExperimentEditorBetaTemplate = observer(ExperimentEditorBetaTemplateFC);

export default ExperimentEditorBetaTemplate;

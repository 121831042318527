import { AssetMetadata, AssetNode } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { DataType, Delimiters } from '@/constants/SystemConstants';
import { assetsRequestService } from '@/services/request-services/AssetsRequestService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeWithParam } from '@/types/AppSettingsTypes';

class AssetTreeViewerViewModel {
  protected _assetsTreeData: AssetNode[] = [];

  protected _loadTemplate: ActionTypeWithParam;
  protected _setAssetTreeData: ActionTypeWithParam;
  protected _setFilterValue: ActionTypeWithParam;
  protected _setLoadingAsset: ActionTypeWithParam;

  constructor(rootStore: RootStore, loadTemplate: ActionTypeWithParam) {
    const { assetTreeViewerStore } = rootStore;
    const { assetsTreeData, setAssetTreeData, setFilterTreeData, setLoadingAsset } = assetTreeViewerStore;

    this._assetsTreeData = assetsTreeData;

    this._loadTemplate = loadTemplate;
    this._setAssetTreeData = setAssetTreeData;
    this._setFilterValue = setFilterTreeData;
    this._setLoadingAsset = setLoadingAsset;
  }

  public filterByName = (value: string) => {
    if (!value.trim()) {
      this._setFilterValue(this._assetsTreeData);
      return;
    }

    this._setLoadingAsset(true);

    const copiedData = JSON.parse(JSON.stringify(this._assetsTreeData)); // Deep copy the data
    const filteredData = copiedData.filter((node) => this.searchChildren(node, value));
    this._setFilterValue(filteredData);

    this._setLoadingAsset(false);
  };

  private searchChildren = (node: AssetNode, value: string): boolean => {
    if (node.name.toLowerCase().includes(value.toLowerCase()) && node.isLeaf) {
      return true;
    }

    if (node.children) {
      node.children = node.children.filter((child) => this.searchChildren(child, value));

      if (node.children.length > 0) {
        return true;
      }
    }

    return false;
  };

  private sortNodes = (a: AssetNode, b: AssetNode) => {
    if (a.isLeaf && !b.isLeaf) {
      return 1;
    }

    if (!a.isLeaf && b.isLeaf) {
      return -1;
    }

    const diff = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    return diff;
  };

  private generateAssetTree = (node: AssetNode, tree: AssetNode[]) => {
    const path = node.path.split(Delimiters.FORWARD_SLASH);

    if (path.length === 0) {
      return;
    }

    let currentNode = tree.find((node: AssetNode) => node.name === path[0]);

    if (currentNode) {
      currentNode = this.addNodeToTree(path.slice(1), node, currentNode);
    } else {
      let newNode: AssetNode = {
        path: path[0],
        name: path[0],
        key: path[0],
        children: [],
        isLeaf: false,
        contentIds: [],
        createdBy: '',
        description: '',
        id: '',
        owner: '',
        reference: '',
        support: '',
        tags: [],
        type: '',
      };

      tree.push(newNode);

      newNode = this.addNodeToTree(path.slice(1), node, newNode);
    }

    return tree;
  };

  private addNodeToTree = (path: string[], node: AssetNode, tree: AssetNode) => {
    if (path.length === 1) {
      tree.children.push(node);
      tree.children.sort(this.sortNodes);
      return;
    }

    const currentNode = tree.children.find((node: AssetNode) => node.name === path[0]);

    if (currentNode) {
      this.addNodeToTree(path.slice(1), node, currentNode);
    } else {
      const newNode: AssetNode = {
        path: path[0],
        name: path[0],
        key: path[0],
        children: [],
        isLeaf: false,
        contentIds: [],
        createdBy: '',
        description: '',
        id: '',
        owner: '',
        reference: '',
        support: '',
        tags: [],
        type: '',
      };

      tree.children.push(newNode);
      tree.children.sort(this.sortNodes);
      this.addNodeToTree(path.slice(1), node, newNode);
    }

    return currentNode;
  };

  private convertAssetsToTree = (assets: AssetMetadata[]): void => {
    let treeData: AssetNode[] = [];

    assets.forEach((asset: AssetMetadata) => {
      const assetName: string[] = asset.name.split(Delimiters.FORWARD_SLASH);

      const assetNode: AssetNode = {
        contentIds: asset.contentIds,
        createdBy: asset.createdBy,
        description: asset.description,
        id: asset.id,
        isLeaf: true,
        key: asset.id,
        name: assetName[assetName.length - 1],
        owner: asset.owner,
        path: asset.type + '/' + asset.name,
        support: asset.support,
        reference: `{\n    "$ref": "${asset.name}"\n}`,
        tags: asset.tags,
        type: asset.type,
      };

      treeData = this.generateAssetTree(assetNode, treeData);
    });

    treeData.sort(this.sortNodes);

    this._setAssetTreeData(treeData);
    this._setFilterValue(treeData);
  };

  public loadAssetsMetadata = async () => {
    this._setLoadingAsset(true);

    const data: AssetMetadata[] = await assetsRequestService.getAllMetadata();

    this.convertAssetsToTree(data);

    this._setLoadingAsset(false);
  };

  public loadTemplate = (template: AssetNode) => {
    this._loadTemplate(template);
  };
}

export default AssetTreeViewerViewModel;

import React from 'react';
import { observer } from 'mobx-react-lite';

import { LabEntity, LabManifest, LabSystemType } from '@/components/ManageLab/LabSystems/LabSystemsType';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { LabSystemDetailsGeneralTemplate } from './LabSystemDetailsGeneralTemplate';
import { LabSystemDetailsGeneralVMType } from './LabSystemDetailsGeneralType';

interface LabSystemDetailsGeneralViewControllerProps {
  viewModel: LabSystemDetailsGeneralVMType;
}

const LabSystemDetailsGeneralViewControllerFC: React.FC<LabSystemDetailsGeneralViewControllerProps> = ({ viewModel }) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labSystemsStore, labSystemDetailsGeneralStore, systemMessageStore } = rootStore;
  const { setSystemGeneralInfo } = labSystemDetailsGeneralStore;
  const { selectedLabManifest, selectedSystem } = labSystemsStore;

  const { clearNonPersistentGlobalMessages } = systemMessageStore;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    const system: LabSystemType = selectedSystem;
    const labManifest: LabManifest = selectedLabManifest;

    if (labManifest && system) {
      const labEntity: LabEntity = labManifest.labEntities?.find(
        (labEntity: LabEntity) => labEntity?.id.toUpperCase() === system.agentId.toUpperCase(),
      );
      const sarmIpAddress: string = labEntity?.metadata?.acCycleRackId;

      system.sarmIpAddress = sarmIpAddress;
      setSystemGeneralInfo(system);
    } else {
      setSystemGeneralInfo(selectedSystem);
    }
  }, [selectedLabManifest, selectedSystem]);

  return <LabSystemDetailsGeneralTemplate></LabSystemDetailsGeneralTemplate>;
};

export const LabSystemDetailsGeneralViewController = observer(LabSystemDetailsGeneralViewControllerFC);

import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';

class LabSystemDetailsGeneralViewModel {
  protected appSettingsStore: AppSettingsStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore } = rootStore;

    this.appSettingsStore = appSettingsStore;
  }

  // Functions will be written in future
}

export default LabSystemDetailsGeneralViewModel;

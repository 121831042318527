import React from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-components';
import { t } from 'i18next';

import { Delimiters, Namespaces as NS } from '@/constants/SystemConstants';
import { Common } from '@/constants/TranslationConstants';
import GenearalInfoBlock from '@/partials/GeneralInfoBlock/GeneralInfoBlock';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import Config from './LabSystemDetailsGeneral.config.json';
import { LabSystemDetailsGeneralAccordionKeys, LabSystemDetailsGeneralVCType } from './LabSystemDetailsGeneralType';

import '@/styles/Accordion.css';
import styles from './LabSystemDetailsGeneral.module.css';

const LabSystemDetailsGeneralTemplateFC: React.FC<LabSystemDetailsGeneralVCType> = (props: LabSystemDetailsGeneralVCType) => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labSystemDetailsGeneralStore } = rootStore;
  const { systemGeneralInfo } = labSystemDetailsGeneralStore;

  // Other Const
  const accordionOpenItems: string[] = [LabSystemDetailsGeneralAccordionKeys.ESSENTIALS];

  const essentialBlock = (
    <div>
      <GenearalInfoBlock
        generalInfo={systemGeneralInfo}
        configFields={Config.labSystemGeneralFields}
        translationMap={NS.TABLE}
        isDualColumnLayout={true}
      ></GenearalInfoBlock>
      <div>
        <div className={styles.row}>
          <div className={styles.label}>{t(Common.TAGS, { ns: NS.COMMON })}</div>
          <div className={styles.separator}>{Delimiters.COLON}</div>
          <div className={styles.value}>
            <div className={styles.tags}>{systemGeneralInfo?.displayTags}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const accordionContent = (
    <div>
      <Accordion collapsible={true} multiple={true} defaultOpenItems={accordionOpenItems} className="accordion-content-container">
        <AccordionItem className="accordion-item" value={LabSystemDetailsGeneralAccordionKeys.ESSENTIALS}>
          <AccordionHeader expandIconPosition={'start'} className="accordion-title">
            {t(Common.ESSENTIALS, { ns: NS.EXPERIMENTS })}
          </AccordionHeader>
          <AccordionPanel className="accordion-panel">
            <div className={styles['essentials-block']}>
              <div className={styles['details']}>{essentialBlock}</div>
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );

  const generalContent = accordionContent;

  return generalContent;
};

export const LabSystemDetailsGeneralTemplate = observer(LabSystemDetailsGeneralTemplateFC);

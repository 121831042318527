import BaseRequestService from './RequestService';

class LabDetailsRequestService extends BaseRequestService {
  async getSystemDetails(labId: number) {
    const url = `lab-systems/${labId}/systems-and-racks`;
    const { data } = await this.get(url);

    return data;
  }

  async getSystemsHeartbeat(agentIds: string[]) {
    if (agentIds?.length === 0) {
      return [];
    }

    const url = 'heartbeats/systems';
    const { data } = await this.post(url, agentIds);

    return data;
  }

  async getLabManifest(labId: number) {
    const url = `labs/${labId}/lab-manifest`;
    const { data } = await this.get(url);

    return data;
  }

  async getSarmIpAddress(labId: number, agentId: string) {
    const url = `labs/${labId}/systems/${agentId}/sarm-ip-address`;
    const { data } = await this.get(url);

    return data;
  }

  // entities param has the agentIds along with the SARM IP Address
  async saveSarmIpDetails(entities: string[]) {
    const url = 'labs/systems/sarm-ip-address';
    const { data } = await this.post(url, entities);

    return data;
  }

  async deleteSarmIpDetails(labId: number, agentIds: string[]) {
    const { data } = await this.put(`labs/${labId}/systems/sarm-ip-address`, agentIds);

    return data;
  }
}

export const ganymedeLabDetailsRequestService = new LabDetailsRequestService();

const Common = {
  BETA: 'beta',
  CLICK_TO_UPDATE: 'click-to-Update',
  CLOSE: 'close',
  COPY_TO_CLIPBOARD: 'copy-to-clipboard',
  CREATE: 'create',
  DELETE: 'delete',
  DOWNLOAD_FAILURE_TEMPLATE: 'download-failure-template',
  DUPLICATE_DATA: 'duplicate-data',
  EDIT: 'edit',
  EDIT_COLUMNS: 'edit-columns',
  ENTER_FIELD: 'enter-field',
  ESSENTIALS: 'essentials',
  GENERAL: 'general',
  INVALID_EMAIL: 'invalid-email',
  INVALID_IP: 'invalid-ip',
  LAYOUT: 'layout',
  LEGACY: 'legacy',
  NO_ITEMS: 'no-items',
  REFRESH: 'refresh',
  RESET: 'reset',
  RESULTS: 'results',
  SAVE: 'save',
  SEARCH_TEXT: 'search-text',
  SEARCH_USER_PLACEHOLDER: 'search-user-placeholder',
  SELECT_IPADDRESS: 'select-ipaddress',
  SELECT_USER: 'select-user',
  TAGS: 'tags',
};

const Default = {
  CREATE_EXPERIMENT: 'create-experiment',
  IP_ADDRESS: 'ip-address',
};

const Editor = {
  DOWNLOAD_FAILED_REPORT: 'download-failed-report',
  DOWNLOAD_SUCCESS_REPORT: 'download-success-report',
};

const Errors = {
  QUEUED_EXPERIMENT_ERROR: 'queued-experiment-error',
  QUEUED_EXPERIMENT_SUCCESS: 'queued-experiment-successfully',
};

const Experiments = {
  VIEW_EXPERIMENT_STATUS: 'view-experiment-status',
};

const LabDetails = {
  ASSIGN_SYSTEM_TAG: 'assign-system-tag',
  ASSIGN_SYSTEM_TAG_PLACEHOLDER: 'assign-system-tag-placeholder',
  ASSIGN_SYSTEM_TAG_SUB_TITLE: 'assign-system-tag-sub-title',
  CORRECT_MISMATCHING_IP: 'correct-mismatching-ip',
  CORRECT_MISMATCHING_MAC: 'correct-mismatching-mac',
  GENERAL: 'general',
  LAB_ACTIONS: 'lab-actions',
  MACHINE_STATUS: 'machine-status',
  MACHINE_TYPE: 'machine-type',
  MANAGE_PREBOOTED_SUT: 'manage-prebooted-sut',
  MANAGE_RACK_MANGER: 'manage-rack-manager',
  NEW_VERSION: 'new-version',
  REPAIR_LAB: 'repair-lab',
  SARM_ADD_SUCCESS: 'sarm-add-success',
  SARM_ADD_SUCCESS_TEMPLATE: 'sarm-add-success-template',
  SARM_ALLOWS_ONE_RACK: 'sarm-allows-one-rack',
  SARM_ALLOWS_SUT_ONLY: 'sarm-allows-sut-only',
  SARM_DELETE: 'sarm-delete',
  SARM_DELETE_CONFIRMATION_TEMPLATE: 'sarm-delete-confirmation-template',
  SARM_DELETE_SUCCESS: 'sarm-delete-success',
  SARM_DELETE_SUCCESS_TEMPLATE: 'sarm-delete-success-template',
  SARM_IP: 'sarm-ip',
  SARM_NOT_ASSOCIATED_TEMPLATE: 'sarm-not-associated-template',
  SARM_NOT_EXIST: 'sarm-not-exist',
  SARM_SUB_TITLE: 'sarm-sub-title',
  SARM_UPDATE: 'sarm-update',
  SAVE_LAB_DETAILS: 'save-lab-details',
  SEARCH_LAB: 'search-lab',
  SYSTEM: 'system',
  SELECT_SYSTEM: 'select-system',
  SYSTEM_ACTIONS: 'system-actions',
  SYSTEM_TAG_NORACK_ALLOWED: 'system-tag-norack-allowed',
  SYSTEMS: 'systems',
};

const Labs = {
  ADD_ADMIN: 'add-admin',
  ADMIN: 'admin',
  ALL_LABS: 'all-labs',
  COMPANY: 'company',
  CREATE_LAB: 'create-lab',
  CREATE_LAB_SUCCESS: 'create-lab-success',
  DELETE_LAB: 'delete-lab',
  DELETE_SUCCESS_TEMPLATE: 'delete-success-template',
  DOWNLOAD_EXE: 'download-exe',
  DOWNLOAD_INSTALLER: 'download-installer',
  DOWNLOAD_MSI: 'download-msi',
  DOWNLOAD_WAIT_TIME_REQUEST: 'download-wait-time-request',
  FIX_IP_ADDRESS: 'fix-ip-address',
  FIX_MAC_ADDRESS: 'fix-mac-address',
  IP_ADDRESS: 'ip-address',
  IP_ADDRESS_ERROR: 'ip-address-error',
  LAB_NAME: 'lab-name',
  LAB_NAME_MISMATCH: 'lab-name-mismatch',
  LAB_NAME_TO_DELETE: 'lab-name-to-delete',
  LABS: 'labs',
  MAC_ADDRESS: 'mac-address',
  MAC_ADDRESS_ERROR: 'mac-address-error',
  MANAGE_USERS: 'manage-users',
  NEW_IP_ADDRESS: 'new-ip-address',
  NEW_MAC_ADDRESS: 'new-mac-address',
  OLD_IP_ADDRESS: 'old-ip-address',
  OLD_MAC_ADDRESS: 'old-mac-address',
  OS_IMAGE: 'os-image',
  PASSWORD_REFERENCE: 'password-reference',
  REPAIR_MACHINE: 'repair-machine',
  REPAIR_REQUEST_ERROR_TEMPLATE: 'repair-request-error-template',
  REPAIR_REQUEST_SUCCESS_TEMPLATE: 'repair-request-success-template',
  SAVE_LAB: 'save-lab',
  SEARCH_LAB: 'search-lab',
  SELECT_COMPANY: 'select-company',
  SELECT_LAB_ADMIN: 'select-lab-admin',
  SELECT_OS_IMAGE: 'select-os-image',
  UPDATE_LAB: 'update-lab',
  UPDATE_LAB_CONFIRMATION: 'update-lab-confirmation',
  USERNAME: 'username',
  VIEW_ALL_IMAGES: 'view-all-images',
  VIEW_LAB: 'view-lab',
};

const LogsView = {
  DOWNLOAD_LOG_START: 'download-logs-start',
  DOWNLOAD_TO_VIEW: 'download-to-view',
  LOGS: 'logs',
  LOGS_NOT_FOUND: 'logs-not-found',
  LOGS_NOT_FOUND_TEXT: 'logs-not-found-text',
  PREVIEW_NOT_AVAILABLE: 'preview-not-available',
  SESSION_DETAIS_STEPS_TEMPLATE: 'session-details-steps-template',
};

const PageCommandBar = {
  GROUPBY_NONE: 'group-by-none',
  GROUP_BY_TEMPLATE: 'group-by-template',
};

const PaginationAria = {
  FIRST_PAGE: 'first-page',
  LAST_PAGE: 'last-page',
  NEXT_PAGE: 'next-page',
  PAGE_SIZE_TEXT: 'page-size-text',
  PREVIOUS_PAGE: 'previous-page',
};

const Results = {
  COPY_URL: 'copy-url',
  DOWNLOAD_FILE: 'download-file',
  DOWNLOAD_LOGS: 'download-logs',
  DOWNLOAD_START: 'download-start',
  DOWNLOAD_TEXT: 'download-text',
  LARGE_FILE_MESSAGE: 'large-file-message',
  RESULT_ANALYZER: 'result-analyzer',
  RESULT_EXPLORER: 'result-explorer',
  ZIP_WARNING_TEMPLATE: 'zip-warning-message-template',
};

const Session = {
  FAILURE_ANALYSIS: 'failure-analysis',
  IP_ADDRESS: 'ip-address',
  SEARCH_IP_ADDRESS: 'search-ip-address',
  SEARCH_STEP_NAME: 'search-step-name',
};

export {
  Common,
  Default,
  Editor,
  Errors,
  Experiments,
  LabDetails,
  Labs,
  LogsView,
  PageCommandBar,
  PaginationAria,
  Results,
  Session,
};

import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Label, MessageBarType, PrimaryButton, SpinnerSize, TextField } from '@fluentui/react';
import { t } from 'i18next';
import format from 'string-template';

import { LabEntity, LabManifest, LabSystemType } from '@/components/ManageLab/LabSystems/LabSystemsType';
import { Buttons } from '@/constants/LabsConstants';
import { FailGroupIds, InfoGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { Common, Default, LabDetails } from '@/constants/TranslationConstants';
import DialogBox from '@/partials/Dialog/DialogTemplate';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { ganymedeLabDetailsRequestService } from '@/services/request-services/LabDetailsRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';
import { isValidIp } from '@/utils/Helpers';

import style from './SarmIpAddress.module.css';

const SarmIpAddressFC: React.FC = () => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, labDetailsStore, labSystemsStore, systemMessageStore } = rootStore;
  const { selectedLabId } = labDetailsStore;
  const {
    hideSarmIpAddressDelete,
    hideSarmIpAddressEditor,
    isSarmIpAddressDeleteOpen,
    isSarmIpAddressEditorOpen,
    selectedLabManifest,
    selectedSystems,
    setSelectedLabManifest,
  } = labSystemsStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  // State Const
  const [sarmIpAddress, setSarmIpAddress] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Other Const
  const agentIds: string[] = selectedSystems.map((system: LabSystemType) => system.agentId);
  const title: string = t(isSarmIpAddressEditorOpen ? LabDetails.SARM_UPDATE : LabDetails.SARM_DELETE, { ns: NS.LAB_DETAILS });
  const hidden: boolean = !isSarmIpAddressEditorOpen && !isSarmIpAddressDeleteOpen;
  const minWidth: string = isSarmIpAddressEditorOpen ? '30%' : '20%';
  const onDismiss: () => void = isSarmIpAddressEditorOpen ? hideSarmIpAddressEditor : hideSarmIpAddressDelete;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (selectedSystems?.length > 0) {
      getExistingSarmIpAddress();
    }
  }, [selectedSystems]);

  const getExistingSarmIpAddress = () => {
    const labManifest: LabManifest = selectedLabManifest;
    let prevSarmIpAddress: string = null;
    let finalSarmIpAddress = '';
    let doesSarmIpExist = false;

    if (labManifest) {
      for (const system of selectedSystems) {
        const labEntity: LabEntity = labManifest.labEntities?.find(
          (labEntity: LabEntity) => labEntity?.id.toUpperCase() === system.agentId.toUpperCase(),
        );
        const sarmIpAddress: string = labEntity?.metadata?.acCycleRackId;

        if (isSarmIpAddressDeleteOpen && sarmIpAddress) {
          doesSarmIpExist = true;
        }

        // Check if it's the first iteration
        if (prevSarmIpAddress === null) {
          prevSarmIpAddress = sarmIpAddress;
        }

        if (sarmIpAddress !== prevSarmIpAddress) {
          finalSarmIpAddress = '';
          break;
        }

        finalSarmIpAddress = sarmIpAddress || '';
      }
    }

    if (isSarmIpAddressDeleteOpen && !doesSarmIpExist) {
      const infoMessage: SystemMessageType = {
        message: t(LabDetails.SARM_NOT_EXIST, { ns: NS.LAB_DETAILS }),
        type: MessageBarType.info,
        groupId: InfoGroupIds.LAB_DETAILS,
      };

      addGlobalMessage(infoMessage);
      hideSarmIpAddressDelete();
    }

    setSarmIpAddress(finalSarmIpAddress);
  };

  const validateIPAddress = (): boolean => {
    let message = '';

    if (sarmIpAddress.length === 0) {
      message = t(Common.ENTER_FIELD, { ns: NS.COMMON });
    } else {
      const isValidIpAddress: boolean = isValidIp(sarmIpAddress);

      if (!isValidIpAddress) {
        message = t(Common.INVALID_IP, { ns: NS.COMMON });
      }
    }

    if (message) {
      const failMessage: SystemMessageType = {
        message: message,
        type: MessageBarType.error,
        groupId: FailGroupIds.SARM_IP,
        showInPopup: true,
      };

      addGlobalMessage(failMessage);

      return false;
    }

    return true;
  };

  const updateLabManifest = async () => {
    try {
      const labManifest: LabManifest = await ganymedeLabDetailsRequestService.getLabManifest(selectedLabId);

      if (labManifest) {
        setSelectedLabManifest(labManifest);
      } else {
        setSelectedLabManifest(null);
      }
    } catch (error) {
      const handleErrorProps = {
        error,
        systemMessageStore: systemMessageStore,
        appSettingsStore: appSettingsStore,
        FailGroupIds: FailGroupIds.LAB_DETAILS,
      };

      HandleError(handleErrorProps);

      setSelectedLabManifest(null);
    }
  };

  const saveSarmIpAddress = async (event: any): Promise<void> => {
    event.preventDefault();

    const isValidIP: boolean = validateIPAddress();

    if (isValidIP) {
      const labEntities: string[] = agentIds;

      setIsLoading(true);
      labEntities.push(sarmIpAddress);

      await ganymedeLabDetailsRequestService
        .saveSarmIpDetails(labEntities)
        .then(async (response: any) => {
          if (response) {
            const sarmSingleMachineMessage: string = format(t(LabDetails.SARM_ADD_SUCCESS_TEMPLATE, { ns: NS.LAB_DETAILS }), {
              item: selectedSystems[0]?.systemName,
            });
            const sarmMultipleMachineMessage: string = t(LabDetails.SARM_ADD_SUCCESS, { ns: NS.LAB_DETAILS });
            const sarmAddMessage: string = selectedSystems?.length === 1 ? sarmSingleMachineMessage : sarmMultipleMachineMessage;

            const successMessage: SystemMessageType = {
              message: sarmAddMessage,
              type: MessageBarType.success,
              groupId: SuccessGroupIds.LAB_DETAILS,
            };

            addGlobalMessage(successMessage);
          }

          labEntities.pop();

          await updateLabManifest();
          setSarmIpAddress(response);
          setIsLoading(false);
          hideSarmIpAddressEditor();
        })
        .catch((error) => {
          console.error('Error while saving SARM IP :', error);
          labEntities.pop();

          setIsLoading(false);
        });
    }
  };

  const deleteSarmIpAddress = async (): Promise<void> => {
    setIsLoading(true);

    await ganymedeLabDetailsRequestService
      .deleteSarmIpDetails(selectedLabId, agentIds)
      .then(async (response: any) => {
        if (response) {
          const sarmSingleMachineMessage: string = format(t(LabDetails.SARM_DELETE_SUCCESS_TEMPLATE, { ns: NS.LAB_DETAILS }), {
            item: selectedSystems[0]?.systemName,
          });

          await updateLabManifest();

          const sarmMultipleMachineMessage: string = t(LabDetails.SARM_DELETE_SUCCESS, { ns: NS.LAB_DETAILS });
          const sarmDeleteMessage: string = selectedSystems?.length === 1 ? sarmSingleMachineMessage : sarmMultipleMachineMessage;

          const successMessage: SystemMessageType = {
            message: sarmDeleteMessage,
            type: MessageBarType.success,
            groupId: SuccessGroupIds.LAB_CONTROL,
          };

          addGlobalMessage(successMessage);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore: systemMessageStore,
          appSettingsStore: appSettingsStore,
          FailGroupIds: FailGroupIds.LAB_DETAILS,
        };

        HandleError(handleErrorProps);
      })
      .finally(() => {
        setIsLoading(false);
        hideSarmIpAddressDelete();
      });
  };

  const sarmIpAddressDeleteMessage: string = format(t(LabDetails.SARM_DELETE_CONFIRMATION_TEMPLATE, { ns: NS.LAB_DETAILS }), {
    sarmIp: sarmIpAddress,
  });

  const validationMessages: SystemMessageType[] = globalMessages.filter((f) => f.showInPopup);
  const editorBody = (
    <div className={style['sarm-wrapper']}>
      <MessageBarTemplate>{validationMessages}</MessageBarTemplate>
      <div>
        <div className={style['row']}>
          <Label className={style['label']}>{t(Default.IP_ADDRESS, { ns: NS.DEFAULT })}</Label>
          <TextField
            required
            type="text"
            title={t(Default.IP_ADDRESS, { ns: NS.DEFAULT })}
            value={sarmIpAddress}
            onChange={(e) => setSarmIpAddress((e.target as any).value)}
            className={style['input']}
          />
        </div>
        <div className={`${style['row']}`}>
          <PrimaryButton className={`${style['button']}`} onClick={saveSarmIpAddress}>
            {t(Common.SAVE, { ns: NS.COMMON })}
          </PrimaryButton>
          <DefaultButton className={`${style['button']}`} onClick={hideSarmIpAddressEditor}>
            {Buttons.cancel}
          </DefaultButton>
        </div>
      </div>
    </div>
  );

  const deleteBody = (
    <div className={style['sarm-wrapper']}>
      <MessageBarTemplate>{validationMessages}</MessageBarTemplate>
      <div>
        {sarmIpAddressDeleteMessage}
        <div className={`${style['row']}`}>
          <DefaultButton className={`${style['default-button']} ${style['danger-button']}`} onClick={deleteSarmIpAddress}>
            {t(Common.DELETE, { ns: NS.COMMON })}
          </DefaultButton>
          <DefaultButton onClick={hideSarmIpAddressDelete}>{t(Common.CLOSE, { ns: NS.COMMON })}</DefaultButton>
        </div>
      </div>
    </div>
  );

  return (
    <DialogBox
      title={title}
      minWidth={minWidth}
      body={
        <div>
          {isSarmIpAddressEditorOpen ? editorBody : deleteBody}
          {isLoading && (
            <div className={style['overlay-spinner']}>
              <LoadingSpinner size={SpinnerSize.medium} />
            </div>
          )}
        </div>
      }
      hidden={hidden}
      modalProps={{
        isBlocking: true,
      }}
      onDismiss={onDismiss}
    />
  );
};

export const SarmIpAddress = observer(SarmIpAddressFC);

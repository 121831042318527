import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import AssetTreeViewerTemplate from '@/components/AssetTreeViewer/AssetTreeViewerTemplate';
import { AssetNode, AssetTreeViewerVMType } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { assetsRequestService } from '@/services/request-services/AssetsRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const AssetTreeViewerViewControllerFC: React.FC<{ viewModel: AssetTreeViewerVMType }> = (props: {
  viewModel: AssetTreeViewerVMType;
}) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { assetTreeViewerStore } = rootStore;
  const { assetsTreeData, filterTreeData, setFilterValue } = assetTreeViewerStore;
  const { viewModel } = props;
  const { filterByName, loadAssetsMetadata, loadTemplate } = viewModel;

  React.useEffect(() => {
    loadAssetsMetadata();
  }, []);

  const generateActions = (node: AssetNode) => {
    return [
      {
        key: 'open-json',
        text: t('open-json', { ns: NS.EDITOR }),
        title: t('open-json', { ns: NS.EDITOR }),
        onClick: () => {
          loadTemplate(node);
        },
      },
      {
        key: 'get-reference',
        text: t('get-reference', { ns: NS.EDITOR }),
        title: t('get-reference', { ns: NS.EDITOR }),
        onClick: () => {
          navigator.clipboard.writeText(node.reference).catch((err) => {
            console.error('[AssetTreeViewerViewControllerFC:generateActions] Failed to copy to clipboard', err);
          });
        },
      },
      {
        key: 'copy-json' + node.name,
        text: t('copy-json', { ns: NS.EDITOR }),
        title: t('copy-json', { ns: NS.EDITOR }),
        onClick: async () => {
          if (node.contentIds.length === 0) {
            console.error('[AssetTreeViewerViewControllerFC:generateActions] No content IDs available for node', node.name);
            return;
          }

          await assetsRequestService.getContent(node.contentIds[node.contentIds.length - 1]).then((data) => {
            navigator.clipboard.writeText(JSON.stringify(data.data, null, 4)).catch((err) => {
              console.error('[AssetTreeViewerViewControllerFC:generateActions]Failed to copy to clipboard', err);
            });
          });
        },
      },
      {
        key: 'download-json',
        text: t('download-json', { ns: NS.EDITOR }),
        title: t('download-json', { ns: NS.EDITOR }),
        onClick: async () => {
          if (node.contentIds.length === 0) {
            console.error('[AssetTreeViewerViewControllerFC:generateActions] No content IDs available for node', node.name);
            return;
          }

          await assetsRequestService.getContent(node.contentIds[node.contentIds.length - 1]).then((data) => {
            const blob = new Blob([JSON.stringify(data.data, null, 4)], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${node.name}.json`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          });
        },
      },
    ];
  };

  return (
    <AssetTreeViewerTemplate
      filterByName={filterByName}
      generateActions={generateActions}
      selectTemplate={function (node: AssetNode): void {
        console.log('selectTemplate', node);
      }}
      treeData={filterTreeData}
    />
  );
};

const AssetTreeViewerViewController = observer(AssetTreeViewerViewControllerFC);

export default AssetTreeViewerViewController;

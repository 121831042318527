import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';

import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import { ActionTypeVoid, ActionTypeWithParam } from '@/types/AppSettingsTypes';

type ExperimentEditorVMType = {
  // No properties
};

type ExperimentEditorVCType = {
  experimentName: IOverflowSetItemProps[];
  farItems: ICommandBarItemProps[];
  filterItems: IOverflowSetItemProps[];
  hideConfirmModal: boolean;
  hidePublish: boolean;
  hidePreview: boolean;
  isConfirmModalOpen: boolean;
  labId: number;
  labToggle: boolean;
  loadingExperiment: boolean;
  splitPanelConfig: SplitPanelConfigType;

  confirmedAction: ActionTypeVoid;
  editorMount: ActionTypeWithParam;
  handleEditorChange: ActionTypeWithParam;
  loadTemplate: ActionTypeWithParam;
  toggleHidePublish: ActionTypeWithParam;
  toggleHidePreview: ActionTypeWithParam;
};

type ExperimentTemplate = {
  experiment: string;
};

type ExperimentTemplateInfo = {
  description: string;
  id: string;
  schema: string;
};

type LabUpgradeTemplateInfo = {
  labId: string;
  manifestVersion: string;
  templateName: string;
};

enum ConfirmationAction {
  LocalFile,
  New,
  Template,
  None,
}

type ExperimentEditorRouteType = {
  labId: string;
};

export {
  ConfirmationAction,
  ExperimentEditorRouteType,
  ExperimentEditorVCType,
  ExperimentEditorVMType,
  ExperimentTemplate,
  ExperimentTemplateInfo,
  LabUpgradeTemplateInfo,
};

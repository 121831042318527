import { action, makeObservable, observable } from 'mobx';
import axios from 'axios';
import { container } from 'tsyringe';

import SessionsStore from '@/components/Sessions/SessionsStore';
import { SessionStepType } from '@/components/Sessions/SessionsTypes';
import { Delimiters } from '@/constants/SystemConstants';
import { GanymedeSessionRequestService } from '@/services/request-services/SessionRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { formatDate } from '@/utils/Dates';

class SessionStepModel {
  private userSettingsStore: UserSettingsStore = container.resolve(UserSettingsStore);

  @observable id: string;
  @observable experimentId: string;
  @observable name?: string;
  @observable displayName?: string;
  @observable experimentGroup?: string;
  @observable stepName?: string;
  @observable machineName?: string;
  @observable agentId?: string;
  @observable ipAddress?: string;
  @observable startTime?: string;
  @observable endTime?: string;
  @observable lastModifiedTime?: string;
  @observable status?: string;
  @observable stepType?: string;
  @observable sequence?: number;
  @observable location?: string;
  @observable stepJSON?: string | unknown;

  constructor(sessionStep: SessionStepType) {
    this.initialize(sessionStep);
    makeObservable(this);
  }

  private initialize(data: SessionStepType) {
    const { timeZone } = this.userSettingsStore;

    this.id = data.id;
    this.experimentId = data.experimentId;
    this.name = data.name;
    this.displayName = data.name;
    this.experimentGroup = data.experimentGroup;
    this.stepName = data.stepName;
    this.machineName = data.machineName;
    this.agentId = data.agentId;
    this.ipAddress = data.ipAddress;
    this.startTime = formatDate(data.startTime, timeZone);
    this.endTime = formatDate(data.endTime, timeZone);
    this.lastModifiedTime = data.lastModifiedTime;
    this.status = data.status;
    this.stepType = data.stepType;
    this.sequence = data.sequence;
    this.location = data.location || Delimiters.DASH;
    this.stepJSON = data.stepJSON || '{}';
  }

  @action
  public static async load(): Promise<SessionStepModel[]> {
    const appSettings = container.resolve(AppSettingsStore);
    const sessionsStore = container.resolve(SessionsStore);

    const { isDebugMode } = appSettings;
    const { selectedSession } = sessionsStore;

    const sessionId: string = selectedSession.id;

    isDebugMode && console.log('[SessionStep:load] Loading Session Steps for SessionId:', sessionId);

    if (selectedSession?.steps?.length > 0) {
      isDebugMode && console.log('[SessionStep:load] Session Steps already loaded for SessionId:', sessionId);
      return selectedSession.steps as SessionStepModel[];
    }

    const cancelToken = axios.CancelToken.source().token;
    const data: SessionStepType[] = await GanymedeSessionRequestService.getSessionSteps(sessionId, cancelToken);
    isDebugMode && console.log('[SessionStep:load] Steps Data received for SessionId:', sessionId, data);

    if (data) {
      isDebugMode && console.log('[SessionStep:load] Session Steps loaded and done for SessionId:', sessionId);
      return data.map((step: SessionStepType) => new SessionStepModel(step));
    }

    return [];
  }
}

export default SessionStepModel;

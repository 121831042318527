/*----------------------------------------------------------------------------------------------------------------------------
# Sessions.tsx: 
# This Component is our Sessions Component. 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import { container } from 'tsyringe';

import SessionDetails from '@/components/SessionDetails/SessionDetailsWrapper';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { SessionsRouteType } from '@/components/Sessions/SessionsTypes';
import SessionsWrapper from '@/components/Sessions/SessionsWrapper';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import AppSettingsStore from '@/stores/AppSettingsStore';
import SystemMessageStore from '@/stores/SystemMessageStore';

const SessionsFC: React.FC = (): React.ReactElement => {
  const sessionsStore: SessionsStore = container.resolve(SessionsStore);
  const systemMessageStore: SystemMessageStore = container.resolve(SystemMessageStore);
  const appSettingsStore: AppSettingsStore = container.resolve(AppSettingsStore);
  const {
    addGeneralFadingMessage,
    addHelpMessage,
    clearNonPersistentGlobalMessages,
    globalMessages,
  } = systemMessageStore;
  const { clearSelectedSession, isDeepLinkMode, pathSessionId, setIsDeepLinkMode, setPathSessionId } = sessionsStore;
  const { isMockDataMode } = appSettingsStore;

  const { experimentId } = useParams<SessionsRouteType>();
  const experimentName = sessionsStore.selectedSession?.name ?? '';

  React.useEffect(() => {
    if (experimentId) {
      // We are in Deep Link Mode.
      setIsDeepLinkMode(true);
      setPathSessionId(experimentId);
    } else {
      setIsDeepLinkMode(false);
      setPathSessionId(null);
      clearSelectedSession();
    }
  }, [experimentId, pathSessionId, isDeepLinkMode, setIsDeepLinkMode, setPathSessionId]);

  const title = t('experiments', { ns: NS.EXPERIMENTS });

  const dataMode = isMockDataMode ? 'mock-data-on' : 'mock-data-off';

  React.useEffect(() => {
    addGeneralFadingMessage(t(dataMode, { ns: NS.EXPERIMENTS }), dataMode);
  }, [isMockDataMode]);

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [addHelpMessage, clearNonPersistentGlobalMessages]);

  const pageHeader = isDeepLinkMode ?
    <PageHeader>
      {experimentName && <>
        {experimentName}
        {' '}
        {TITLE_DIVIDER}
        {' '}
        {experimentId}
      </>}
    </PageHeader> :
    <PageHeader
      icon={NavigationIcon[Navigation.GANYMEDE.SESSIONS]}
      iconLink={isDeepLinkMode ? Navigation.GANYMEDE.SESSIONS : null}
    >
      {title}
    </PageHeader>;
  return (
    <div className="fullscreen">
      <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <div className="fullscreen padding-top">
          {pageHeader}
          <PageContent scrollable={false}>
            <div className="fullscreen">{
              isDeepLinkMode ?
                <SessionDetails
                  sessionId={experimentId}
                /> :
                <SessionsWrapper />
            }
            </div>
          </PageContent>
        </div>
      </main>
    </div>
  );
};

const Sessions = observer(SessionsFC);

export default Sessions;

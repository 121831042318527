import { CancelToken } from 'axios';

import { SessionFilterType } from '@/components/Sessions/SessionsTypes';
import { FilterOptions } from '@/constants/SystemConstants';

import BaseRequestService from './RequestService';

class SessionRequestService extends BaseRequestService {
  async getExperimentSessions(
    labId: any,
    filters: SessionFilterType,
    instancePageSize: number,
    instancePageNumber: number,
    cancelToken?: CancelToken,
  ) {
    const { endDate, lastRunTimeRange, searchValue, statusValues, startDate } = filters;
    const skip = instancePageSize * instancePageNumber;

    // NOTE:
    // We can only send the following query parameters, defined in the API's ExperimentSessionPropertyResolver class.
    // Valid fields that we can filter by are:
    // id, name, description, owner, created, started, schedule, location, labId, machineIds, instances, and now instanceStatus.

    // Add requests must first filter by the labId.
    const labFilter = `(labId eq ${Number(labId)})`;
    let dataCall = `sessions?$filter=${labFilter}`;
    let totalCall = `sessions/metadata?$filter=${labFilter}`;

    // Add filtering by the Status dropdown.
    if (statusValues?.length > 0) {
      // eslint-disable-next-line quotes
      const filter = ` and (instanceStatus eq '${statusValues.join("' or instanceStatus eq '")}')`;

      dataCall += filter;
      totalCall += filter;
    }

    // Currently only filtering by labId and name
    if (searchValue) {
      const filter = ` and contains(name, '${searchValue}')`;

      dataCall += filter;
      totalCall += filter;
    }

    if (lastRunTimeRange && lastRunTimeRange !== FilterOptions.ALL) {
      if (startDate) {
        const filter = ` and created gt '${startDate}'`;

        dataCall += filter;
        totalCall += filter;
      }

      if (endDate) {
        const filter = ` and created lt '${endDate}'`;

        dataCall += filter;
        totalCall += filter;
      }
    }

    if (skip > 0) {
      dataCall += `&$skip=${skip}`;
    }

    dataCall += `&$top=${instancePageSize}&$orderBy=created desc`;
    totalCall += '&$count=true';

    try {
      const { data } = await this.get(dataCall, null, { cancelToken });
      const count = await this.get(totalCall, null, { cancelToken });

      return { experimentSessions: data, count: count.data[0]?.Count || 0 };
    } catch (error) {
      const message = error.response?.data?.message || error.response?.data || error.message;

      throw new Error('[SessionRequestService.getExperimentSessions] An error has occurred:', message);
    }
  }

  async getExperimentSession(sessionId: string, cancelToken?: CancelToken) {
    if (!sessionId) {
      console.error('[getExperimentSession] Error! SessionId is empty.');

      return null;
    }

    const url = `sessions/${sessionId}`;

    try {
      const { data } = await this.get(url, null, { cancelToken });

      return { data };
    } catch (error) {
      const message = error.response?.data?.message || error.response?.data || error.message;

      throw new Error('[SessionRequestService.getExperimentSession] An error has occurred:', message);
    }
  }

  async getExperimentSessionsStatus(
    labId: any,
    filter: any,
    instancePageSize: number,
    instancePageNumber: number,
    cancelToken?: CancelToken,
  ) {
    const { endDate, lastRunTimeRange, searchValue, startDate, statusValues } = filter;
    const skip = instancePageSize * instancePageNumber;

    // Add requests must first filter by the labId.
    const labFilter = `(labId eq ${Number(labId)})`;
    let dataCall = `sessions/status?$filter=${labFilter}`;
    let totalCall = `sessions/metadata?$filter=${labFilter}`;

    // Add filtering by the Status dropdown.
    if (statusValues?.length > 0) {
      // eslint-disable-next-line quotes
      const filter = ` and (instanceStatus eq '${statusValues.join("' or instanceStatus eq '")}')`;

      dataCall += filter;
      totalCall += filter;
    }

    // Currently only filtering by labId and name
    if (searchValue) {
      const filter = ` and contains(name, '${searchValue}')`;

      dataCall += filter;
      totalCall += filter;
    }

    if (lastRunTimeRange && lastRunTimeRange !== FilterOptions.ALL) {
      if (startDate) {
        const filter = ` and created gt '${startDate}'`;

        dataCall += filter;
        totalCall += filter;
      }

      if (endDate) {
        const filter = ` and created lt '${endDate}'`;

        dataCall += filter;
        totalCall += filter;
      }
    }

    if (skip > 0) {
      dataCall += `&$skip=${skip}`;
    }

    dataCall += `&$top=${instancePageSize}&$orderBy=created desc`;
    totalCall += '&$count=true';

    try {
      const { data } = await this.get(dataCall, null, { cancelToken });
      const count = await this.get(totalCall, null, { cancelToken });

      return { experimentSessions: data, count: count.data[0]?.Count || 0 };
    } catch (error) {
      const message = error.response?.data?.message || error.response?.data || error.message;

      throw new Error('[SessionRequestService.getExperimentSessionsStatus] An error has occurred:', message);
    }
  }

  async getSessionSteps(sessionId: string, cancelToken?: CancelToken) {
    if (!sessionId) {
      console.error('[getSessionSteps] Error! SessionId is empty.');

      return null;
    }

    const url = `sessions/${sessionId}/steps`;

    try {
      const { data } = await this.get(url, { cancelToken });

      return data;
    } catch (error) {
      const message = error.response?.data?.message || error.response?.data || error.message;

      throw new Error('[SessionRequestService.getSessionSteps] An error has occurred:', message);
    }
  }

  async getIPAddress(labId: number, cancelToken?: CancelToken) {
    const url = `lab-systems/${labId}/ip-addresses`;

    try {
      const { data } = await this.get(url, { cancelToken });

      return data;
    } catch (error) {
      const message = error.response?.data?.message || error.response?.data || error.message;

      throw new Error('[SessionRequestService.getIPAddress] An error has occurred:', message);
    }
  }
}

export const GanymedeSessionRequestService = new SessionRequestService();

import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
const CustomLoggingProviderList = ['Vega.Execution.Providers.Workloads.VirtualClientProvider'];

const Dates = {
  DEFAULT_RECENT_DAYS: 30,
  MIN_DAYS: 30,
  MAX_DAYS: 180,
};
const NoLoggingProviderList = [
  'Vega.Execution.Providers.Environment.EnvironmentSelectionProvider',
  'Microsoft.Azure.CRC.Execution.Providers.AutoTriageProvider',
  'Vega.Execution.Providers.Fabric.Monitors.AddOobTelemetryMonitor',
];

const QueueOptions = {
  BATCH_SIZE: 50,
  MAX_REPORT_SIZE: 5,
};

const StatusOptions: IDropdownOption[] = [
  { key: 'Cancelled', text: 'Cancelled' },
  { key: 'Failed', text: 'Failed' },
  { key: 'InProgress', text: 'InProgress' },
  { key: 'Pending', text: 'Pending' },
  { key: 'Succeeded', text: 'Succeeded' },
];

const Statuses = {
  CANCELLED: 'cancelled',
  ERROR: 'error',
  FAILED: 'failed',
  INPROGRESS: 'inprogress',
  IN_PROGRESS: 'in progress',
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  UNKNOWN: 'unknown',
};

const CancellableStatuses = [Statuses.INPROGRESS, Statuses.PENDING];

const StatusesWithLogs = [Statuses.FAILED, Statuses.SUCCEEDED];

const StatusClasses = {
  [Statuses.CANCELLED]: 'cancelled',
  [Statuses.ERROR]: 'error',
  [Statuses.FAILED]: 'failed',
  [Statuses.INPROGRESS]: 'inprogress',
  [Statuses.PENDING]: 'pending',
  [Statuses.SUCCEEDED]: 'succeeded',
  [Statuses.UNKNOWN]: 'unknown',
};

const StatusIcons = {
  [Statuses.CANCELLED]: 'Blocked2Solid',
  [Statuses.ERROR]: 'StatusErrorFull',
  [Statuses.FAILED]: 'StatusErrorFull',
  [Statuses.INPROGRESS]: 'SkypeCircleClock',
  [Statuses.PENDING]: 'AlertSolid',
  [Statuses.SUCCEEDED]: 'SkypeCircleCheck',
  [Statuses.UNKNOWN]: 'Unknown',
};

enum StepStatusTypes {
  CANCELLED = 'Cancelled',
  FAILED = 'Failed',
  IN_PROGRESS = 'InProgress',
  NOT_STARTED = 'NotStarted',
  SUCCEEDED = 'Succeeded',
  SYSTEM_CANCELLED = 'SystemCancelled',
  PENDING = 'Pending',
}

enum TabMemoryKeys {
  EXPERIMENT_INSTANCE_MODAL = 'experiment-instance-modal',
  SESSION_DETAILS_MODAL = 'session-details-modal',
  SESSION_DETAILS_MODAL_JSON = 'session-details-modal-json',
  SESSION_DETAILS_PANEL = 'session-details-panel',
}

enum ViewMode {
  DETAILS_VIEW = 'details',
  LIST_VIEW = 'list',
}

export {
  CancellableStatuses,
  CustomLoggingProviderList,
  Dates,
  NoLoggingProviderList,
  QueueOptions,
  StatusClasses,
  Statuses,
  StatusesWithLogs,
  StatusIcons,
  StatusOptions,
  StepStatusTypes,
  TabMemoryKeys,
  ViewMode,
};

import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
// import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
// import { IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { t } from 'i18next';
import format from 'string-template';
import { container } from 'tsyringe';

import SessionDetailsStepsFilters from '@/components/SessionDetails/Sections/SessionDetailsSteps/Components/Filter/SessionDetailsStepsFilters';
import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import SessionStepModel from '@/components/SessionDetails/SessionStepModel';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { TimeRangeOptions } from '@/constants/DateFormatConstants';
import { Delimiters, FilterOptions, KeyTextPair, Namespaces as NS, UniqueListKey } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { createGroupByNoneItem } from '@/utils/GroupBy';
import { getUniqueList } from '@/utils/Helpers';

class SessionDetailsStepsFilterViewController {
  private sessionsStore: SessionsStore = container.resolve(SessionsStore);
  private userSettingsStore: UserSettingsStore = container.resolve(UserSettingsStore);

  @observable public filters: SessionDetailsStepsFilters;
  @observable public groupByButtonLabel: string;
  public openColumnEditor: () => void;

  constructor(
    public handleGroupBySelected: (column: string) => void,
    public groupStepsBy: string,
    filters: SessionDetailsStepsFilters,
    openColumnEditor,
  ) {
    makeObservable(this);
    this.filters = filters;
    this.setGroupByButtonLabel(groupStepsBy);
    this.openColumnEditor = openColumnEditor;
  }

  @action.bound
  setGroupByButtonLabel(key: string) {
    const label: string = t(key, { ns: NS.TABLE });
    const groupByMessage: string = format(t(PageCommandBar.GROUP_BY_TEMPLATE, { ns: NS.TABLE }), {
      group: label,
    });
    this.groupByButtonLabel = key === PageCommandBar.GROUPBY_NONE ? label : groupByMessage;
  }

  @computed
  get timeZone(): string {
    return this.userSettingsStore.timeZone;
  }
  @computed
  get uniqueStepsList(): (key: any, noStatusMessage?: string) => KeyTextPair[] {
    return (key: any, noStatusMessage?: string): KeyTextPair[] => {
      const steps: SessionStepModel[] = this.sessionsStore.selectedSession?.steps;

      if (!steps || steps.length === 0) {
        return [];
      }

      const uniqueList = getUniqueList(steps, key);

      if (key === UniqueListKey.STATUS && noStatusMessage) {
        return uniqueList.length > 0 ? uniqueList : [{ key: noStatusMessage, text: noStatusMessage }];
      }

      return uniqueList;
    };
  }

  @action.bound
  setSearchTerm(_: React.ChangeEvent<HTMLInputElement>, newValue: string) {
    this.filters.searchValue = newValue;
  }

  @action.bound
  setIpAddressSearchTerm(_: React.ChangeEvent<HTMLInputElement>, newValue: string) {
    this.filters.searchIpAddress = newValue;
  }

  @action.bound
  statusFilterChanged(_: React.FormEvent<HTMLDivElement>, option: any) {
    const key: string = option.key as string;
    const hasKey: boolean = option.selected && !this.filters.statusValues.includes(key);

    if (hasKey) {
      this.filters.statusValues = [...this.filters.statusValues, key];
    } else if (!option.selected && this.filters.statusValues.includes(key)) {
      this.filters.statusValues = this.filters.statusValues.filter((key: string) => key !== option.key);
    }
  }

  @action.bound
  stepFilterChanged(_: React.FormEvent<HTMLDivElement>, option: any) {
    const key: string = option.key as string;
    const hasKey: boolean = option.selected && !this.filters.typeValues.includes(key);

    if (hasKey) {
      this.filters.typeValues = [...this.filters.typeValues, key];
    } else if (!option.selected && this.filters.typeValues.includes(key)) {
      this.filters.typeValues = this.filters.typeValues.filter((key: string) => key !== option.key);
    }
  }

  @action.bound
  ipAddressFilterChanged(_: React.FormEvent<HTMLDivElement>, option: any) {
    const key: string = option.key as string;
    const isOptionSelected: boolean = option.selected;
    const isKeyIncluded: boolean = this.filters.ipAddresses.includes(key);

    if (isOptionSelected && !isKeyIncluded) {
      this.filters.ipAddresses = [...this.filters.ipAddresses, key];
    } else if (!isOptionSelected && isKeyIncluded) {
      this.filters.ipAddresses = this.filters.ipAddresses.filter((key: string) => key !== option.key);
    }
  }

  //region time selection
  @action.bound
  onSelectTime(_: React.FormEvent<HTMLDivElement>, option: any /* IChoiceGroupOption */) {
    const now: Date = new Date();
    const startDate: Date = new Date();
    const { key } = option;

    this.filters.timeRange = key;

    if (key !== FilterOptions.ALL && key !== FilterOptions.CUSTOM) {
      startDate.setHours(now.getHours() - Number(key));
      this.filters.startDate = startDate;
      this.filters.endDate = now;
    }
  }

  @action.bound
  onSelectStartDate(endDate: Date) {
    this.filters.startDate = endDate;
  }

  @action.bound
  onSelectEndDate(endDate: Date) {
    this.filters.endDate = endDate;
  }

  @computed
  public get timePickerLabel(): string {
    if (this.filters.timeRange && this.filters.timeRange !== FilterOptions.ALL) {
      const timeRange = TimeRangeOptions.find((timeRange) => timeRange.key === this.filters.timeRange);

      if (timeRange) {
        return timeRange.text;
      }
    }

    return t('end-date', { ns: NS.TABLE });
  }

  //endregion

  @action.bound
  resetButtonClicked() {
    this.filters.reset();
  }

  @computed
  get groupByColumnItems() {
    const groupByNoneItem: ICommandBarItemProps = createGroupByNoneItem((_: string, key: string) => {
      this.setGroupByButtonLabel(key);
      this.handleGroupBySelected(key);
    });
    const groupByItems: ICommandBarItemProps[] = SessionDetailsStore.SESSION_STEPS_COLUMN_DEFINITIONS.filter(
      (item) => item.isGroupBy,
    )
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((item: any) => {
        const label = t(item.name, { ns: NS.TABLE });

        return {
          key: item.key,
          text: label,
          onClick: () => {
            this.setGroupByButtonLabel(item.key);
            this.handleGroupBySelected(item.key);
          },
        };
      });

    return [groupByNoneItem, ...groupByItems];
  }
}

export default SessionDetailsStepsFilterViewController;

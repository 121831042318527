import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { CancelToken } from 'axios';
import { t } from 'i18next';
import ls from 'local-storage';
import format from 'string-template';
import { container } from 'tsyringe';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import SessionModel from '@/components/Sessions/SessionModel';
import SessionsStore from '@/components/Sessions/SessionsStore';
import { SessionGUIType } from '@/components/Sessions/SessionsTypes';
import SessionsViewModel from '@/components/Sessions/SessionsViewModel';
import { FailGroupIds, Files, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import { LogsView } from '@/constants/TranslationConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { resultsExplorerEndpoint } from '@/utils/Env';
import MsalAuthorization from '@/utils/MsalAuthorization';

import SessionDetailsStore from './SessionDetailsStore';

class SessionDetailsViewModel {
  protected appSettingsStore: AppSettingsStore = container.resolve(AppSettingsStore);
  protected sessionsStore: SessionsStore = container.resolve(SessionsStore);
  protected sessionDetailsStore: SessionDetailsStore = container.resolve(SessionDetailsStore);
  protected systemMessageStore: SystemMessageStore = container.resolve(SystemMessageStore);

  public sessionId: string;

  constructor(sessionId: string) {
    this.sessionId = sessionId;
  }

  public loadSession = async (sessionId: string, cancelToken: CancelToken): Promise<void> => {
    const { isDebugMode } = this.appSettingsStore;
    const { clearSelectedSession, selectedSession, setSelectedSession } = this.sessionsStore;
    const { setLabCompanyName } = this.sessionsStore;
    const { addGlobalMessage } = this.systemMessageStore;

    isDebugMode && console.log(`[SessionsViewModel:loadSession] Loading SessionId: ${sessionId}.`);

    // Check if we already have this sessionId loaded in the store.
    if (selectedSession && selectedSession.id === sessionId) {
      isDebugMode &&
        console.log(`[SessionsViewModel:loadSession] Data already loaded for SessionId: ${sessionId}:`, selectedSession);
      return;
    }

    try {
      const isValidSessionId = !!sessionId;

      if (!isValidSessionId) {
        // No session data to load. Clear the list.
        clearSelectedSession();
      } else {
        const session: SessionModel = await SessionModel.load(sessionId);
        const sessionGui: SessionGUIType = SessionsViewModel.buildSessionViewModel(session);

        if (session) {
          setSelectedSession(sessionGui);
        }

        const company: any = await this.loadCompany(session.location.labName, cancelToken);
        setLabCompanyName(company?.CompanyName);
      }
    } catch (error) {
      console.error('[SessionsViewModel:loadSession] Error Loading Session data:', error);

      const message = error.message;
      const systemMessage: SystemMessageType = {
        id: 'session-api-error',
        message,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'session-api-group',
      };

      clearSelectedSession();
      addGlobalMessage(systemMessage);
    }

    isDebugMode && console.log(`[SessionsViewModel:loadSession] Data loaded for SessionId: ${sessionId}.`);
  };

  public loadCompany = async (locationValue: string, cancelToken: CancelToken): Promise<string> => {
    const labs: LabType[] = await ganymedeLabRequestService.getLabs();
    const labExists: boolean = labs.some((f) => String(f.LabName) === locationValue);

    if (labExists) {
      const selectedLab: LabType = labs?.find((f) => String(f.LabName) === locationValue);

      const companies: any[] = await ganymedeLabRequestService.getCompanies();
      const labCompany: any = companies.find((activeCompany) => activeCompany.CompanyId === selectedLab.CompanyId);
      return labCompany;
    }

    return null;
  };

  public logDownloader = (path: string, companyName?: string) => {
    const { addSessionPanelMessage, sessionPanelMessages } = this.sessionDetailsStore;
    const outputFile = Files.LOGS_ZIP;

    const startMessage: SystemMessageType = {
      message: t(LogsView.DOWNLOAD_LOG_START, { ns: NS.EXPERIMENTS }),
      namespace: NS.EXPERIMENTS,
      type: FluentMessageBarType.info,
      groupId: SuccessGroupIds.SESSION_DETAILS,
    };

    addSessionPanelMessage(startMessage);

    const baseUrl = resultsExplorerEndpoint;
    const downloadPath = companyName
      ? `${baseUrl}/file/download/folder?resultCollectionId=${path}&companyName=${companyName}`
      : `${baseUrl}/file/download/folder?resultCollectionId=${path}`;

    this.downloadUrl(downloadPath, outputFile);
  };

  private downloadUrl = async (url: any, filename: string) => {
    const { addSessionPanelMessage, enableDownloadButton } = this.sessionDetailsStore;
    const outputFile = Files.LOGS_ZIP;
    const sessionDetailsErrorGroupId = '';

    const headers = new Headers();

    if (filename === outputFile) {
      headers.append('Authorization', `Bearer ${(ls as any).get('resultsToken')}`);
    } else {
      const token = await MsalAuthorization.getToken();

      headers.append('Authorization', `Bearer ${token}`);
    }

    try {
      const response = await fetch(url, { headers });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const successMessage: SystemMessageType = {
        message: format(t('download-logs-complete', { ns: NS.EXPERIMENTS }), {
          url,
          status: response.status,
          statusText: response.statusText,
        }),
        namespace: NS.EXPERIMENTS,
        type: FluentMessageBarType.success,
        groupId: SuccessGroupIds.SESSION_DETAILS,
      };

      const element = document.createElement('a');

      element.setAttribute('href', URL.createObjectURL(blob));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      element.click();

      addSessionPanelMessage(successMessage);
      enableDownloadButton();
    } catch (error) {
      console.error('[SessionsViewModel:downloadUrl] Download failed:', error);

      const failMessage: SystemMessageType = {
        message: format(t('download-failed-template', { ns: NS.EXPERIMENTS }), {
          url,
          status: error.status,
          statusText: error.statusText,
        }),
        namespace: NS.EXPERIMENTS,
        type: FluentMessageBarType.error,
        groupId: FailGroupIds.SESSION_DETAILS,
      };

      addSessionPanelMessage(failMessage);
      enableDownloadButton();
    }
  };
}

export default SessionDetailsViewModel;

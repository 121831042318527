import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconButton } from '@fluentui/react';
import { t } from 'i18next';

import SessionDetailsStepsViewController from '@/components/SessionDetails/Sections/SessionDetailsSteps/SessionDetailsStepsViewController';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { LogsView, Session } from '@/constants/TranslationConstants';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';

import styles from '@/components/SessionDetails/SessionDetails.module.css';

type SessionDetailsStepsLinksPanePartialProps = {
  viewController: SessionDetailsStepsViewController;
};

const SessionDetailsStepsLinksPanePartial: React.FC<SessionDetailsStepsLinksPanePartialProps> = (
  props: SessionDetailsStepsLinksPanePartialProps,
) => {
  const { viewController } = props;

  const {
    labCompanyName,
    selectedStep,
    showFailureAnalysisLink,
    logPath,
    showLogs,
    stepsLinkClassName,
    logLinkClassName,
    failureAnalysisLinkClassName,
  } = viewController;

  const stepsJsonLink = (
    <div className={stepsLinkClassName} onClick={viewController.selectSessionStepsPane}>
      <IconButton aria-label={t('steps-json', { ns: NS.EXPERIMENTS })} iconProps={{ iconName: SystemIcons.STEPS_JSON }} />
      {t('steps-json', { ns: NS.EXPERIMENTS })}
    </div>
  );

  const logsLink = (
    <div className={logLinkClassName} onClick={viewController.selectLogWindowPane}>
      <IconButton aria-label={t(LogsView.LOGS, { ns: NS.LOGS_VIEW })} iconProps={{ iconName: SystemIcons.LOGS }} />
      {t(LogsView.LOGS, { ns: NS.LOGS_VIEW })}
    </div>
  );

  const logs = showLogs ? (
    <>
      <div className={styles['row-divider']}></div>
      <RawLogsTreeView
        id={selectedStep?.experimentId}
        companyName={labCompanyName}
        path={logPath}
        showLogText={false}
        isTitleHighlighted={viewController.isLogsPaneSelected}
        onTreeClick={viewController.selectLogWindowPane}
        executedBy={LogsView.SESSION_DETAIS_STEPS_TEMPLATE}
      />
    </>
  ) : (
    <></>
  );

  const failureAnalysisLink = showFailureAnalysisLink ? (
    <div className={failureAnalysisLinkClassName} onClick={viewController.selectFailureWindowPane}>
      <IconButton
        aria-label={t(Session.FAILURE_ANALYSIS, { ns: NS.EXPERIMENTS })}
        iconProps={{ iconName: SystemIcons.FAILURE_ANALYSIS }}
      />
      {t(Session.FAILURE_ANALYSIS, { ns: NS.EXPERIMENTS })}
    </div>
  ) : (
    <></>
  );

  return (
    <div className={`${styles['flex-column-container']} ${styles['step-details-list-pane']}`}>
      {stepsJsonLink}
      <div className={styles['row-divider']}></div>
      {logsLink}
      {logs}
      <div className={styles['row-divider']}></div>
      {failureAnalysisLink}
    </div>
  );
};

const SessionDetailsStepsLinksPane = observer(SessionDetailsStepsLinksPanePartial);

export default SessionDetailsStepsLinksPane;

import { MessageBarType } from '@fluentui/react';
import { IObjectWithKey } from '@fluentui/react/lib/DetailsList';
import { Selection } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import LabDetailsPanelStore from '@/components/ManageLab/LabDetailsPanel/LabDetailsPanelStore';
import { PanelPage } from '@/constants/LabDetailsConstants';
import {
  ColumnEditorKeys,
  DataType,
  FailGroupIds,
  FilterOptions,
  InfoGroupIds,
  KeyTextPair,
  Labels,
  MachineTypes,
  Namespaces as NS,
  SortedColumnPageKeys,
} from '@/constants/SystemConstants';
import { LabDetails } from '@/constants/TranslationConstants';
import ColumnEditorStore from '@/partials/ColumnEditor/ColumnEditorStore';
import { paginateData } from '@/partials/Pagination/Pagination';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import TableViewStore from '@/partials/TableView/TableViewStore';
import { ganymedeExperimentRequestService } from '@/services/request-services/ExperimentRequestService';
import { ganymedeLabDetailsRequestService } from '@/services/request-services/LabDetailsRequestService';
import { ganymedeTagRequestService } from '@/services/request-services/TagRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { HandleError } from '@/utils/_labs/HandleError';
import { formatDate } from '@/utils/Dates';
import { displayTag, getMachineIconType, getSelectedRowIndices, getUniqueList, populateHealthStatus } from '@/utils/Helpers';
import { processColumns } from '@/utils/Tables';

import config from './LabSystems.config.json';
import LabSystemStore from './LabSystemsStore';
import { HeartbeatType, LabManifest, LabSystemType, RackSystem } from './LabSystemsType';

class LabSystemsViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected editColumnsStore: ColumnEditorStore;
  protected paginationStore: PaginationStore;
  protected systemMessageStore: SystemMessageStore;
  protected tableViewStore: TableViewStore;
  protected userSettingsStore: UserSettingsStore;

  protected labDetailsStore: LabDetailsStore;
  protected labSystemsStore: LabSystemStore;
  protected labDetailsPanelStore: LabDetailsPanelStore;

  constructor(rootStore: RootStore) {
    const {
      appSettingsStore,
      editColumnsStore,
      labDetailsPanelStore,
      labDetailsStore,
      labSystemsStore,
      paginationStore,
      systemMessageStore,
      tableViewStore,
      userSettingsStore,
    } = rootStore;

    this.appSettingsStore = appSettingsStore;
    this.editColumnsStore = editColumnsStore;
    this.labDetailsPanelStore = labDetailsPanelStore;
    this.labDetailsStore = labDetailsStore;
    this.labSystemsStore = labSystemsStore;
    this.paginationStore = paginationStore;
    this.tableViewStore = tableViewStore;
    this.systemMessageStore = systemMessageStore;
    this.userSettingsStore = userSettingsStore;
  }

  fetchColumnDetails = async (): Promise<void> => {
    const { getEditorColumns } = this.editColumnsStore;
    const { labSystems, setLabSystemsEntireColumns, setLabSystemsColumnList } = this.labSystemsStore;
    const { selectedLab } = this.labDetailsStore;

    if (selectedLab && labSystems?.length > 0) {
      const columnEditorKey = ColumnEditorKeys.LAB_SYSTEMS;
      const columnDefinitions: any = await this.getColumnDefinitions();
      const storedColumns = getEditorColumns(columnEditorKey);

      if (columnDefinitions) {
        const { userColumns, allColumns } = processColumns(storedColumns, columnDefinitions);

        setLabSystemsColumnList(userColumns);
        setLabSystemsEntireColumns(allColumns);
      }
    }
  };

  fetchLabSystems = async (): Promise<void> => {
    const { selectedLabId } = this.labDetailsStore;
    const { doReset, setLabSystems, setIsLoading, clearSelectedSystems } = this.labSystemsStore;

    doReset(true);
    setIsLoading(true);

    try {
      const results: any = await ganymedeLabDetailsRequestService.getSystemDetails(selectedLabId);

      if (results?.length > 0) {
        const mappedData: LabSystemType[] = await this.buildDataMapping(results, selectedLabId);

        setLabSystems(mappedData);
      } else {
        setLabSystems([]);
      }
    } catch (error) {
      const handleErrorProps = {
        error,
        systemMessageStore: this.systemMessageStore,
        appSettingsStore: this.appSettingsStore,
        FailGroupIds: FailGroupIds.LAB_DETAILS,
      };

      HandleError(handleErrorProps);

      setLabSystems([]);
    } finally {
      clearSelectedSystems();
      setIsLoading(false);
    }
  };

  fetchLabManifest = async (): Promise<void> => {
    const { selectedLabId } = this.labDetailsStore;
    const { setSelectedLabManifest } = this.labSystemsStore;

    try {
      const labManifest: LabManifest = await ganymedeLabDetailsRequestService.getLabManifest(selectedLabId);

      if (labManifest) {
        setSelectedLabManifest(labManifest);
      } else {
        setSelectedLabManifest(null);
      }
    } catch (error) {
      const handleErrorProps = {
        error,
        systemMessageStore: this.systemMessageStore,
        appSettingsStore: this.appSettingsStore,
        FailGroupIds: FailGroupIds.LAB_DETAILS,
      };

      HandleError(handleErrorProps);

      setSelectedLabManifest(null);
    }
  };

  protected getSystemsHeartbeat = async (): Promise<HeartbeatType[] | null> => {
    const { agentIds } = this.labSystemsStore;

    await ganymedeLabDetailsRequestService
      .getSystemsHeartbeat(agentIds)
      .then((result: HeartbeatType[]) => {
        return result;
      })
      .catch((error) => {
        console.error('Error while getting Systems Heartbeat', error);

        return null;
      });

    return null;
  };

  buildDataMapping = async (results: any[], labId: number): Promise<LabSystemType[]> => {
    const { setAgentIds, setMachineTypesList } = this.labSystemsStore;
    const { timeZone } = this.userSettingsStore;

    const machineTypes: KeyTextPair[] = [];
    const agentIds: string[] = [];

    const mappedData: LabSystemType[] = await Promise.all(
      results.map(async (result: any) => {
        const {
          HostSystemId: hostSystemId = '',
          MacAddress: macAddress,
          IPAddress: ipAddress,
          Name: systemName,
          SlotNumber: slotNumber,
          RackSerialNumber: rackSerialNumber,
          MachineId: systemId,
          OsImageName: osImageName,
          Status: state,
        } = result;

        const resultMachineType: string = result.Type.toUpperCase();
        const machineType: string = MachineTypes[resultMachineType as string];
        const displayMachineType: JSX.Element = getMachineIconType(resultMachineType as string);
        const hostMachineType: KeyTextPair = { key: machineType, text: machineType };
        const doesMachineTypeExist = machineTypes.some((type: KeyTextPair) => type.key === hostMachineType.key);

        const lastUpdate: string = formatDate(result.LastUpdate, timeZone);
        const hardwareInfo: JSX.Element | string = FilterOptions.NA; // This needs to be changed
        const socInfo: JSX.Element | string = FilterOptions.NA; // This needs to be changed

        const { displayHealthStatus: displayMachineHealthStatus, healthStatus: machineHealthStatus } = populateHealthStatus(
          Labels.UNKNOWN,
        ) as { displayHealthStatus: JSX.Element | null; healthStatus: string | null };

        const displayTags: JSX.Element = result.Tags.map((tag: any, index: number) => displayTag(tag?.TagName, index));
        const agentId = `${labId},${macAddress},${slotNumber},${ipAddress}`;

        agentIds.push(agentId);

        if (!doesMachineTypeExist) {
          machineTypes.push(hostMachineType);
        }

        return {
          systemId,
          machineType,
          displayMachineType,
          systemName,
          ipAddress,
          macAddress,
          rackSerialNumber,
          hostSystemId,
          slotNumber,
          agentId,
          osImageName,
          state,
          lastUpdate,
          machineHealthStatus,
          displayMachineHealthStatus,
          displayTags,
          hardwareInfo,
          socInfo,
        };
      }),
    );

    if (machineTypes?.length > 0) {
      setMachineTypesList(machineTypes);
    }

    setAgentIds(agentIds);
    return mappedData;
  };

  getIpAddressList = async (): Promise<void> => {
    const { selectedLab } = this.labDetailsStore;
    const { setIpAddressesList } = this.labSystemsStore;

    try {
      const ipAddresses: string[] = await ganymedeExperimentRequestService.getIPAddress(selectedLab.LabId);
      const ipAddressKeyTextPair: KeyTextPair[] = ipAddresses.map((ip: string) => ({ key: ip, text: ip }));

      setIpAddressesList(ipAddressKeyTextPair);
    } catch (error) {
      console.error('Error fetching IP Address:', error);

      setIpAddressesList([]);
    }
  };

  getFilteredResult = (): LabSystemType[] => {
    const { ipAddressValues, machineTypeValues, healthStatusValues, labSystems } = this.labSystemsStore;

    const applyFilter = (
      data: LabSystemType[],
      filterValues: string[],
      fieldExtractor: (item: LabSystemType) => string,
    ): LabSystemType[] => {
      if (filterValues.length) {
        return data.filter((item) => filterValues.includes(fieldExtractor(item) || ''));
      }

      return data;
    };

    let filteredResult: LabSystemType[] = labSystems;

    filteredResult = applyFilter(filteredResult, ipAddressValues, (e: LabSystemType) => e.ipAddress);
    filteredResult = applyFilter(filteredResult, machineTypeValues, (e: LabSystemType) => e.machineType);
    filteredResult = applyFilter(filteredResult, healthStatusValues, (e: LabSystemType) => e.machineHealthStatus);

    return filteredResult;
  };

  searchData = (currentPage?: number): void => {
    const { ipAddressValues, machineTypeValues, healthStatusValues, labSystems, setFilteredLabSystems } = this.labSystemsStore;
    const { paginationType, setPaginationType } = this.paginationStore;

    if (labSystems?.length > 0) {
      const hasFiltersApplied = ipAddressValues?.length > 0 || machineTypeValues?.length > 0 || healthStatusValues?.length > 0;
      const filteredResult: LabSystemType[] = this.getFilteredResult();

      const { paginatedItems, updatedPaginationType } = paginateData(
        filteredResult,
        paginationType,
        hasFiltersApplied,
        currentPage,
      );

      setPaginationType(updatedPaginationType);
      setFilteredLabSystems(paginatedItems);
    } else {
      setFilteredLabSystems([]);
    }
  };

  protected getHealthStatusColumns = async (systemHeartbeats?: HeartbeatType[]): Promise<any[]> => {
    const displayStatusField = 'display-machine-health-status';
    const statusField = 'machine-health-status';
    const rackSystems: RackSystem[] = [];

    let healthInsight: {
      displayHealthStatus: JSX.Element | null;
      healthStatus: string | null;
    } = null;

    const columnDefinitions = config?.labSystemsColumnDefinitions?.map((column: any) => {
      if (systemHeartbeats && (column.key === displayStatusField || column.key === statusField)) {
        return {
          ...column,
          onRender: (labSystem: LabSystemType) => {
            if (labSystem.machineType.toUpperCase() === MachineTypes.RACK.toUpperCase()) {
              healthInsight = this.calculateRackHealth(labSystem, rackSystems);
            } else {
              healthInsight = this.calculateMachineHealth(systemHeartbeats, labSystem);

              const rackSystem: RackSystem = this.getRackSystem(labSystem, rackSystems, healthInsight.healthStatus);

              if (rackSystem) {
                rackSystems.push(rackSystem);
              }
            }

            if (column.key === displayStatusField) {
              return healthInsight?.displayHealthStatus || null;
            } else if (column.key === statusField) {
              return healthInsight?.healthStatus || null;
            }
          },
        };
      }

      return column;
    });

    return columnDefinitions;
  };

  getColumnDefinitions = async (): Promise<any> => {
    const { agentIds } = this.labSystemsStore;

    let columnDefinitions: any;

    // To bring the rack to the end of list
    const sortedAgentIds: string[] = agentIds.sort((a: string) => (a.includes(DataType.NULL) ? 1 : -1));

    if (sortedAgentIds?.length > 0) {
      await ganymedeLabDetailsRequestService
        .getSystemsHeartbeat(agentIds)
        .then((systemHeartbeats: HeartbeatType[]) => {
          columnDefinitions = this.getHealthStatusColumns(systemHeartbeats);
        })
        .catch((error) => {
          console.error('Error while getting Systems Heartbeat', error);

          return null;
        });
    } else {
      columnDefinitions = this.getHealthStatusColumns();
    }

    return columnDefinitions;
  };

  handleClearAllFilters = (): void => {
    const { doReset } = this.labSystemsStore;
    const { doResetTableSort } = this.tableViewStore;

    const pageName = SortedColumnPageKeys.LAB_DETAILS_SYSTEMS_PAGE;

    doResetTableSort(pageName);
    doReset();
  };

  assignTagsToSystems = async (selectedTags: string[]): Promise<void> => {
    const { selectedSystems, clearSelectedSystems, setIsLoading } = this.labSystemsStore;
    const { isDebugMode } = this.appSettingsStore;

    if (selectedTags?.length > 0 && selectedSystems?.length > 0) {
      const systemIds: number[] = selectedSystems.map((system: LabSystemType) => system.systemId);

      const systemTagRequests = selectedTags.map(async (tagName) => {
        try {
          const result = await ganymedeTagRequestService.createSystemsTag(systemIds, tagName);

          if (result) {
            isDebugMode && console.log(`[LabSystemViewModel:assignTagsToSystems] Tag applied to systems: ${tagName}`);
          } else {
            console.error(`[LabSystemViewModel:assignTagsToSystems] Failed to apply tag to systems: ${tagName}`);
          }
        } catch (error) {
          const handleErrorProps = {
            error,
            appSettingsStore: this.appSettingsStore,
            failGroupId: FailGroupIds.LAB_CONTROL,
          };

          HandleError(handleErrorProps);
        }
      });

      setIsLoading(true);
      await Promise.all(systemTagRequests);

      clearSelectedSystems();
      this.fetchLabSystems();
    }
  };

  selectRow = (rows: Selection<IObjectWithKey>) => {
    const { setSelectedSystem, setSelectedSystems, setLabItems, setSelectedRacksIndices, setSelectedVirtualMachineIndices } =
      this.labSystemsStore;
    const { setPanelPage, openLabDetailsPanel } = this.labDetailsPanelStore;

    const machineTypeFieldName = 'machineType';
    const selectedItems: LabSystemType[] = rows.getSelection() as LabSystemType[] | null;
    const selectedRows: number = selectedItems?.length | 0;
    const rackIndices: number[] = getSelectedRowIndices(rows, machineTypeFieldName, MachineTypes.RACK);
    const virtualMachineIndices: number[] = getSelectedRowIndices(rows, machineTypeFieldName, MachineTypes.VIRTUALMACHINE);

    if (selectedRows === 0) {
      setSelectedSystem(null);
    } else if (selectedRows === 1) {
      const selection: LabSystemType | null = selectedItems[0];

      setSelectedSystem(selection);
      openLabDetailsPanel();
    }

    setPanelPage(PanelPage.MACHINE_DETAILS);
    setSelectedRacksIndices(rackIndices);
    setSelectedVirtualMachineIndices(virtualMachineIndices);
    setLabItems(rows);
    setSelectedSystems(selectedItems);
  };

  getAddSystemTagOpen = () => {
    const { addGlobalMessage } = this.systemMessageStore;
    const { labItems, selectedRacksIndices, showTagEditor } = this.labSystemsStore;

    this.deselectIndices([...selectedRacksIndices]);

    if (selectedRacksIndices?.length > 0) {
      const infoMessage = {
        message: t(LabDetails.SYSTEM_TAG_NORACK_ALLOWED, { ns: NS.LAB_DETAILS }),
        type: MessageBarType.info,
        groupId: InfoGroupIds.LAB_DETAILS,
      };

      addGlobalMessage(infoMessage);
    }

    if (labItems.getSelection()?.length > 0) {
      showTagEditor();
    }
  };

  private getRackSystem = (labSystem: LabSystemType, rackSystems: RackSystem[], healthStatus: string): RackSystem | null => {
    if (labSystem.rackSerialNumber) {
      const rackSystem: RackSystem = {
        systemName: labSystem.systemName,
        systemRackSerialNumber: labSystem.rackSerialNumber,
        healthStatus,
      };

      const isSystemInRackSystems: boolean = rackSystems.some((system: RackSystem) => system.systemName === rackSystem.systemName);

      if (!isSystemInRackSystems) {
        return rackSystem;
      }
    }

    return null;
  };

  private calculateMachineHealth = (
    systemHeartbeats: HeartbeatType[],
    labSystem: LabSystemType,
  ): { displayHealthStatus: JSX.Element | null; healthStatus: string | null } => {
    const matchingSystemHeartbeat: HeartbeatType = systemHeartbeats?.find(
      (heartbeat: HeartbeatType) => heartbeat.agentId?.toUpperCase() === labSystem.agentId.toUpperCase(),
    );

    const lastModified: string = matchingSystemHeartbeat?.lastModified || null;

    const systemHealthStatus: { displayHealthStatus: JSX.Element | null; healthStatus: string | null } =
      populateHealthStatus(lastModified);

    return systemHealthStatus;
  };

  private calculateRackHealth = (
    labSystem: LabSystemType,
    rackSystems: RackSystem[],
  ): {
    displayHealthStatus: JSX.Element | null;
    healthStatus: string | null;
  } => {
    let rackStatus = '';
    let rackHealthStatus: {
      displayHealthStatus: JSX.Element | null;
      healthStatus: string | null;
    } = null;

    const currentRackSerialNumber: string = labSystem.systemName;

    if (rackSystems?.length > 0) {
      const currentRackSystemsHealth: string[] = Array.from(
        new Set(
          rackSystems
            .filter((rackSystem: RackSystem) => rackSystem?.systemRackSerialNumber === currentRackSerialNumber)
            .map((rackSystem: RackSystem) => rackSystem.healthStatus),
        ),
      );

      if (currentRackSystemsHealth.includes(Labels.UNHEALTHY) || currentRackSystemsHealth.includes(Labels.WARNING)) {
        rackStatus = Labels.UNHEALTHY;
      } else if (currentRackSystemsHealth.every((status: string) => status === Labels.HEALTHY)) {
        rackStatus = Labels.HEALTHY;
      } else {
        rackStatus = Labels.NOT_AVAILABLE;
      }

      rackHealthStatus = populateHealthStatus(rackStatus, null, null, true);
    }

    return rackHealthStatus;
  };

  private deselectIndices(indices: number[]): void {
    const { labItems } = this.labSystemsStore;

    indices.forEach((index) => {
      labItems.setIndexSelected(index, false, false);
    });
  }

  private refineSelectionForSarmIpAddress = () => {
    const { addGlobalMessage } = this.systemMessageStore;
    const { labItems, selectedRacksIndices, selectedVirtualMachineIndices } = this.labSystemsStore;

    this.deselectIndices([...selectedRacksIndices, ...selectedVirtualMachineIndices]);

    if (selectedRacksIndices?.length > 0 || selectedVirtualMachineIndices?.length > 0) {
      const infoMessage = {
        message: t(LabDetails.SARM_ALLOWS_SUT_ONLY, { ns: NS.LAB_DETAILS }),
        type: MessageBarType.info,
        groupId: InfoGroupIds.LAB_DETAILS,
      };

      addGlobalMessage(infoMessage);
    }

    this.selectRow(labItems);
  };

  getSarmIpAddressEditorOpen = () => {
    const { addGlobalMessage } = this.systemMessageStore;
    const { labItems, showSarmIpAddressEditor, selectedSystems } = this.labSystemsStore;

    const rackFieldName = 'rackSerialNumber';

    this.refineSelectionForSarmIpAddress();
    const rackSet: KeyTextPair[] = getUniqueList(selectedSystems, rackFieldName);

    if (rackSet?.length > 1) {
      const infoMessage = {
        message: t(LabDetails.SARM_ALLOWS_ONE_RACK, { ns: NS.LAB_DETAILS }),
        type: MessageBarType.info,
        groupId: InfoGroupIds.LAB_DETAILS,
      };

      addGlobalMessage(infoMessage);
      return;
    }

    if (labItems.getSelection()?.length > 0) {
      showSarmIpAddressEditor();
    }
  };

  getSarmIpAddressDeleteOpen = () => {
    const { labItems, showSarmIpAddressDelete } = this.labSystemsStore;

    this.refineSelectionForSarmIpAddress();

    if (labItems.getSelection()?.length > 0) {
      showSarmIpAddressDelete();
    }
  };
}

export default LabSystemsViewModel;

import React from 'react';
import ReactJson from 'react-json-view';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { observer } from 'mobx-react-lite';
import { Pivot, PivotItem } from '@fluentui/react';
import { t } from 'i18next';
import { container } from 'tsyringe';

import SessionDetailsStepsTemplate from '@/components/SessionDetails/Sections/SessionDetailsSteps/SessionDetailsStepsTemplate';
import SessionDetailsStepsViewController from '@/components/SessionDetails/Sections/SessionDetailsSteps/SessionDetailsStepsViewController';
import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import customStyle from '@/components/SessionDetails/SessionDetailsStyles';
import SessionsStore from '@/components/Sessions/SessionsStore';
import SessionStepChartTemplate from '@/components/SessionStepChart/SessionStepChartTemplate';
import { TabMemoryKeys } from '@/constants/ExperimentConstants';
import { ChartType, Namespaces as NS } from '@/constants/SystemConstants';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import SessionInfoBlock from '@/partials/SessionInfoBlock/SessionInfoBlockTemplate';
import AppSettingsStore from '@/stores/AppSettingsStore';

import { SessionDetailsVCType } from './SessionDetailsTypes';

import '@/styles/SplitPanel.css';
import styles from './SessionDetails.module.css';

const SessionDetailsTemplateFC: React.FC<SessionDetailsVCType> = (props: SessionDetailsVCType) => {
  const appSettingsStore: AppSettingsStore = container.resolve(AppSettingsStore);
  const sessionsStore: SessionsStore = container.resolve(SessionsStore);
  const sessionDetailsStore: SessionDetailsStore = container.resolve(SessionDetailsStore);

  const stepsViewController: SessionDetailsStepsViewController = React.useMemo(() => {
    return new SessionDetailsStepsViewController();
  }, []);

  React.useEffect(() => {
    return () => {
      stepsViewController.destroy();
    };
  }, [stepsViewController]);

  const { isOutlookMode, tabMemory } = appSettingsStore;
  const { isSessionDataLoaded, isViewingDeepLink, readingPaneKey, selectedSession } = sessionsStore;
  const { isSessionLoading } = sessionDetailsStore;
  const { compiledJsonStyle, content, generalCommandBarItems, onJsonLinkClick, onLinkClick, pageHeader, rawJsonStyle, setContent } =
    props;

  const activeTab: string = tabMemory[TabMemoryKeys.SESSION_DETAILS_MODAL];
  const activeJsonTab: string = tabMemory[TabMemoryKeys.SESSION_DETAILS_MODAL_JSON];
  const isLoadingSteps = !selectedSession?.steps;

  React.useEffect(() => {
    const generalContent = () => {
      return (
        <>
          {!isSessionDataLoaded && <LoadingSpinner />}
          <PageCommandBar items={generalCommandBarItems} />
          <div className={`${styles['flex-column-container']}`}>
            <div className={`${styles['scrollable-block']}`}>
              <div className={styles['columns']}>
                <div className={styles['column']}>
                  <SessionInfoBlock session={selectedSession} />
                </div>
                <div className={styles['multi-divider']}></div>
                <div className={styles['column']}>
                  {isLoadingSteps ? <LoadingSpinner /> : <SessionStepChartTemplate type={ChartType.DONUT} data={selectedSession} />}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };

    const sourceJsonTabContent = () => {
      return (
        <>
          {!isSessionDataLoaded && <LoadingSpinner />}
          {isSessionDataLoaded && (
            <div className={`${styles['breakable-block']} ${styles['scrollable-block']} ${styles['syntax-highlighter']}`}>
              <SyntaxHighlighter language="json" style={rawJsonStyle} customStyle={customStyle.jsonBackground} showLineNumbers>
                {selectedSession.rawJson}
              </SyntaxHighlighter>
            </div>
          )}
        </>
      );
    };

    const compiledJsonTabContent = () => {
      const json: object = isSessionDataLoaded ? (selectedSession.json as object) : {};

      return (
        <>
          {!isSessionDataLoaded && <LoadingSpinner />}
          {isSessionDataLoaded && (
            <div className={`${styles['breakable-block']} ${styles['scrollable-block']} ${styles['react-json']}`}>
              {<ReactJson src={json} style={customStyle.jsonBackground} theme={compiledJsonStyle} />}
            </div>
          )}
        </>
      );
    };

    const generalTabItem = (
      <PivotItem
        className={styles['flex-column-container']}
        headerText={t('general', { ns: NS.EXPERIMENTS })}
        key={'tab-general'}
        itemKey={'tab-general'}
      >
        <PageDivider />
        {isSessionDataLoaded && generalContent()}
      </PivotItem>
    );

    const stepsTabItem = (
      <PivotItem
        className={styles['flex-column-container']}
        headerText={t('steps', { ns: NS.EXPERIMENTS })}
        key={'tab-steps'}
        itemKey={'tab-steps'}
      >
        <PageDivider />
        <SessionDetailsStepsTemplate viewController={stepsViewController} />
      </PivotItem>
    );

    const compiledJsonTabItem = (
      <PivotItem
        className={styles['flex-column-container']}
        headerText={t('compiled-json', { ns: NS.EXPERIMENTS })}
        key={'tab-compiled-json'}
        itemKey={'tab-compiled-json'}
      >
        <PageDivider />
        {isSessionDataLoaded && compiledJsonTabContent()}
      </PivotItem>
    );

    const rawJsonTabItem = (
      <PivotItem
        className={styles['flex-column-container']}
        headerText={t('source-json', { ns: NS.EXPERIMENTS })}
        key={'tab-raw-json'}
        itemKey={'tab-raw-json'}
      >
        <PageDivider />
        {isSessionDataLoaded && selectedSession.rawJson && sourceJsonTabContent()}
      </PivotItem>
    );

    const jsonTabItem = (
      <PivotItem
        className={styles['flex-column-container']}
        headerText={t('json', { ns: NS.EXPERIMENTS })}
        key={'tab-json'}
        itemKey={'tab-json'}
      >
        <PageDivider />
        <Pivot className={styles['flex-column-container']} defaultSelectedKey={activeJsonTab} onLinkClick={onJsonLinkClick}>
          {isSessionDataLoaded && selectedSession.rawJson && rawJsonTabItem}
          {isSessionDataLoaded && compiledJsonTabItem}
        </Pivot>
      </PivotItem>
    );

    const content = (
      <>
        <div className={`${styles['modal-body-content']} ${styles[readingPaneKey as string]}`}>
          {isSessionDataLoaded && (
            <>
              {/* NOTE: When displayed within a Side Panel, the title is rendered in that component. */}
              {/* We are viewing a Deep Link, we repress the title. */}
              {isOutlookMode && !isViewingDeepLink && pageHeader}
              <Pivot className={styles['flex-column-container']} defaultSelectedKey={activeTab} onLinkClick={onLinkClick}>
                {generalTabItem}
                {stepsTabItem}
                {jsonTabItem}
              </Pivot>
            </>
          )}
          {!isSessionDataLoaded && (
            <>
              {isViewingDeepLink && <LoadingSpinner />}
              {!isViewingDeepLink && (
                <div className={styles['centered-message']}>{t('select-experiment', { ns: NS.EXPERIMENTS })}</div>
              )}
            </>
          )}
        </div>
      </>
    );

    if (isSessionLoading) {
      setContent(<LoadingSpinner />);
    } else {
      setContent(content);
    }
  }, [isSessionLoading, selectedSession, tabMemory]);

  return (
    <div className={`${styles['flex-column-container']} ${styles['details-body']} ${isOutlookMode ? styles['outlook-mode'] : ''}`}>
      <>{content}</>
    </div>
  );
};

const SessionDetailsTemplate = observer(SessionDetailsTemplateFC);

export default SessionDetailsTemplate;

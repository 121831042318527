import { CancelToken } from 'axios';

import { CreateLabType, LabUser } from '@/components/CreateLab/CreateLabType';
import { RepairLabMachineRequest } from '@/partials/LabComponents/RepairLabMachine/RepairLabMachineTypes';

import BaseRequestService from './RequestService';

class LabRequestService extends BaseRequestService {
  async createNewLab(payload: CreateLabType) {
    const url = 'labs';
    const { data } = await this.post(url, payload);

    return data;
  }

  async getLabs() {
    const url = 'labs';
    const { data } = await this.get(url);

    return data;
  }

  async updateLabName(labId: any, labName: string) {
    const url = `labs/${labId}?labName=${labName}`;
    const { data } = await this.put(url);

    return data;
  }

  async deleteLab(labId: any) {
    const url = `labs/${labId}`;
    const { data } = await this.delete(url);

    return data;
  }

  async getLabDetails(labId: any) {
    const url = `labs/${labId}`;
    const { data } = await this.get(url);

    return data;
  }

  async getCompanies() {
    const url = 'companies';
    const { data } = await this.get(url);

    return data;
  }

  async getOSImages() {
    const url = 'labs/os-images';
    const { data } = await this.get(url);

    return data;
  }

  async getUsers() {
    const url = 'labs/emails';
    const { data } = await this.get(url);

    return data;
  }

  async addUsers(labId: number, labUsers: LabUser[]) {
    const url = `labs/${labId}/users`;
    const { data } = await this.post(url, labUsers);

    return data;
  }

  async addUser(labId: number, labUser: LabUser) {
    const url = `labs/${labId}/user`;
    const { data } = await this.post(url, labUser);

    return data;
  }

  async deleteLabUser(labId: any, user: any) {
    const url = `labs/${labId}/users?userEmail=${user}`;
    const { data } = await this.delete(url);

    return data;
  }

  async getUserLabs() {
    const url = 'labs/users';
    const { data } = await this.get(url);

    return data;
  }

  async getLabsUser(labId: any) {
    const url = `labs/${labId}/users`;
    const { data } = await this.get(url);

    return data;
  }

  async getSystem(labId: any) {
    const url = `lab-systems/${labId}`;
    const { data } = await this.get(url);

    return data;
  }

  async getVirtualSystem(labId: any) {
    const url = `lab-systems/${labId}/virtual-systems`;
    const { data } = await this.get(url);

    return data;
  }

  async updateLab(labId: any, osImageId: any) {
    const url = `labs/${labId}/os-images/${osImageId}`;
    const { data } = await this.put(url);

    return data;
  }

  async repairLabMachine(labId: string, isIPAddressFix: boolean, requestBody: RepairLabMachineRequest, cancelToken?: CancelToken) {
    const url = `lab-systems/${labId}/repair?isIPAddressFix=${isIPAddressFix}`;
    const { data } = await this.post(url, requestBody, { cancelToken });

    return data;
  }

  async getLabManifest() {
    const url = 'manifest-version';
    const { data } = await this.get(url);

    return data;
  }

  async getRackManager(labId: any) {
    const url = `labs/${labId}/racks`;
    const { data } = await this.get(url);

    return data;
  }

  async getVirtualRackManager(labId: any) {
    const url = `labs/${labId}/virtual-racks`;
    const { data } = await this.get(url);

    return data;
  }
}

export const ganymedeLabRequestService = new LabRequestService();

import React from 'react';
import { container, inject, singleton } from 'tsyringe';

import LabsControlStore from '@/components/_labs/Labs/LabsControl/LabsControlStore';
import AssetTreeViewerStore from '@/components/AssetTreeViewer/AssetTreeViewerStore';
import CosmosStore from '@/components/Cosmos/CosmosStore';
import CreateLabStore from '@/components/CreateLab/CreateLabStore';
import DocumentationStore from '@/components/Documentation/DocumentationStore';
import ExperimentDetailsStore from '@/components/ExperimentDetails/ExperimentDetailsStore';
import ExperimentEditorStore from '@/components/ExperimentEditor/ExperimentEditorStore';
import ExperimentsStore from '@/components/Experiments/ExperimentsStore';
import LabListStore from '@/components/LabList/LabListStore';
import LabPanelStore from '@/components/LabPanel/LabPanelStore';
import LogsViewPanelStore from '@/components/LogsViewPanel/LogsViewPanelStore';
import MachineSelectStore from '@/components/MachineSelect/MachineSelectStore';
import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import LabDetailsPanelStore from '@/components/ManageLab/LabDetailsPanel/LabDetailsPanelStore';
import LabGeneralStore from '@/components/ManageLab/LabGeneral/LabGeneralStore';
import LabSystemDetailsStore from '@/components/ManageLab/LabSystemDetails/LabSystemDetailsStore';
import LabSystemDetailsGeneralStore from '@/components/ManageLab/LabSystemDetailsGeneral/LabSystemDetailsGeneralStore';
import LabSystemsStore from '@/components/ManageLab/LabSystems/LabSystemsStore';
import PreviewExperimentStore from '@/components/PreviewExperiment/PreviewExperimentStore';
import ErrorsGraphStore from '@/components/Reliability/ErrorsGraph/ErrorsGraphStore';
import ReliabilityStore from '@/components/Reliability/ReliabilityStore';
import ResultExplorerStore from '@/components/ResultExplorer/ResultExplorerStore';
import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import SessionsStore from '@/components/Sessions/SessionsStore';
import UseGraphStore from '@/components/UseGraph/UseGraphStore';
import UserInfoStore from '@/components/UserInfo/UserInfoStore';
import ColumnEditorStore from '@/partials/ColumnEditor/ColumnEditorStore';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import RawLogsTreeViewStore from '@/partials/RawLogsTreeView/RawLogsTreeViewStore';
import TableViewStore from '@/partials/TableView/TableViewStore';
import LocalStorageService from '@/services/LocalStorageService';
import AirSettingsStore from '@/stores/AirSettingsStore';
import UserSettingsStore from '@/stores/UserSettingsStore';

import AppSettingsStore from './AppSettingsStore';
import SystemMessageStore from './SystemMessageStore';

@singleton()
export class RootStore {
  constructor(
    // Initial store declarations.
    @inject(LocalStorageService) public localStorage: LocalStorageService,
    @inject(AppSettingsStore) public appSettingsStore: AppSettingsStore,
    @inject(SystemMessageStore) public systemMessageStore: SystemMessageStore,
    @inject(AirSettingsStore) public airSettingsStore: AirSettingsStore,
    @inject(UserInfoStore) public userInfoStore: UserInfoStore,

    // Other store declarations.
    @inject(AssetTreeViewerStore) public assetTreeViewerStore: AssetTreeViewerStore,
    @inject(CreateLabStore) public createLabStore: CreateLabStore,
    @inject(CosmosStore) public cosmosStore: CosmosStore,
    @inject(DocumentationStore) public documentationStore: DocumentationStore,
    @inject(ColumnEditorStore) public editColumnsStore: ColumnEditorStore,
    @inject(ErrorsGraphStore) public errorGraphStore: ErrorsGraphStore,
    @inject(ExperimentDetailsStore) public experimentDetailsStore: ExperimentDetailsStore,
    @inject(ExperimentEditorStore) public experimentEditorStore: ExperimentEditorStore,
    @inject(ExperimentsStore) public experimentsStore: ExperimentsStore,
    @inject(LabDetailsPanelStore) public labDetailsPanelStore: LabDetailsPanelStore,
    @inject(LabDetailsStore) public labDetailsStore: LabDetailsStore,
    @inject(LabGeneralStore) public labGeneralStore: LabGeneralStore,
    @inject(LabPanelStore) public labPanelStore: LabPanelStore,
    @inject(LabListStore) public labListStore: LabListStore,
    @inject(LabSystemDetailsStore) public labSystemDetailsStore: LabSystemDetailsStore,
    @inject(LabSystemDetailsGeneralStore) public labSystemDetailsGeneralStore: LabSystemDetailsGeneralStore,
    @inject(LabSystemsStore) public labSystemsStore: LabSystemsStore,
    @inject(LabsControlStore) public labsControlStore: LabsControlStore,
    @inject(LogsViewPanelStore) public logsViewPanelStore: LogsViewPanelStore,
    @inject(MachineSelectStore) public machineSelectStore: MachineSelectStore,
    @inject(PreviewExperimentStore) public previewExperimentStore: PreviewExperimentStore,
    @inject(PaginationStore) public paginationStore: PaginationStore,
    @inject(ReliabilityStore) public reliabilityStore: ReliabilityStore,
    @inject(ResultExplorerStore) public resultExplorerStore: ResultExplorerStore,
    @inject(RawLogsTreeViewStore) public rawLogsTreeViewStore: RawLogsTreeViewStore,
    @inject(SessionsStore) public sessionsStore: SessionsStore,
    @inject(SessionDetailsStore) public sessionDetailsStore: SessionDetailsStore,
    @inject(UseGraphStore) public useGraphStore: UseGraphStore,
    @inject(UserSettingsStore) public userSettingsStore: UserSettingsStore,
    @inject(TableViewStore) public tableViewStore: TableViewStore,
  ) {}
}

const rootStore = container.resolve(RootStore);

export const RootStoreContext = React.createContext(rootStore);

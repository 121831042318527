import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IOverflowSetItemProps } from '@fluentui/react/lib/OverflowSet';
import { useBoolean } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { AssetNode } from '@/components/AssetTreeViewer/AssetTreeViewerTypes';
import { monacoSettings } from '@/components/ExperimentEditor/ExperimentEditor.config';
import ExperimentEditorBetaTemplate from '@/components/ExperimentEditorBeta/ExperimentEditorBetaTemplate';
import {
  ConfirmationAction,
  ExperimentEditorRouteType,
  LabUpgradeTemplateInfo,
} from '@/components/ExperimentEditorBeta/ExperimentEditorBetaTypes';
import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import { ExperimentEditorConstants } from '@/constants/ExperimentEditorConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { FileType, IconNames, Namespaces as NS, ReadingPaneStateKeys } from '@/constants/SystemConstants';
import { darkBase, lightBase } from '@/layouts/Themes/ThemeConstants';
import { assetsRequestService } from '@/services/request-services/AssetsRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { LoadFile, SaveFile, SaveFileAs, SelectFile } from '@/utils/FileIO';

import styles from '@/components/ExperimentEditor/ExperimentEditor.module.css';

declare global {
  interface Window {
    monaco: any;
  }
}

interface ExperimentEditorViewControllerProps {
  viewModel: any;
  labUpgradeTemplateInfo?: LabUpgradeTemplateInfo;
}

// This contains the sub view controllers like FileIO, and also manages the high-level business logic between the sub VCs
const ExperimentEditorBetaViewControllerFC: React.FC<ExperimentEditorViewControllerProps> = ({
  viewModel,
  labUpgradeTemplateInfo,
}) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, experimentEditorStore } = rootStore;
  const { isPartnerMode, isDarkMode } = appSettingsStore;
  const {
    experiment,
    experimentEditorLabsToggle,
    isExperimentLoading,
    isExperimentSaved,
    updateExperiment,
    setExperiment,
    setFileHandle,
    setFileName,
    setLoadingExperiment,
    setExperimentSaved,
    setExperimentEditorLabsToggle,
    setUpdateExperiment,
  } = experimentEditorStore;

  const { airDiagnosticOptions, labsDiagnosticOptions } = monacoSettings;
  const { fileHandle, fileName } = viewModel;
  const { labId } = useParams<ExperimentEditorRouteType>();
  const [labToggle, setLabToggle] = React.useState<boolean>(isPartnerMode || experimentEditorLabsToggle || labId !== undefined);
  const [editor, setEditor] = React.useState<any>(); // Used to keep track if things are changed.
  const [monaco, setMonaco] = React.useState<any>(); // Used to keep track if things are changed.
  const [errorsExist, setErrorsExist] = React.useState<boolean>(false);
  const [hidePublish, { toggle: toggleHidePublish }] = useBoolean(true);
  const [hidePreview, { toggle: toggleHidePreview }] = useBoolean(true);
  // Used to track what action we are confirming.
  const [confirmationAction, setConfirmationAction] = React.useState<ConfirmationAction>(ConfirmationAction.None);
  const [showConfirm, { toggle: toggleShowConfirm }] = useBoolean(false);

  // Container for the menu items
  const _menuItems: ICommandBarItemProps[] = [];
  const _switcherItems: IOverflowSetItemProps[] = [];

  const monacoThemeSettings = {
    base: isDarkMode ? 'vs-dark' : 'vs',
    inherit: true,
    rules: [],
    colors: { 'editor.background': isDarkMode ? darkBase.backgroundColor : lightBase.backgroundColor },
  };

  const splitPanelConfig: SplitPanelConfigType = {
    defaultSize: [500],
    minSize: [200],
    maxSize: [-200], // Allows the pane to be resized to full height minus 200 pixels (preventing loss of border).
    keys: [ReadingPaneStateKeys.EXPERIMENT_EDITOR_VERTICAL],
    offModeSize: [500],
    padding: 200, // Padding to prevent the pane from being resized to the full height of the window.
  };

  React.useEffect(() => {
    const initializeMonaco = () => {
      window.monaco.editor.defineTheme(ExperimentEditorConstants.MONACO_THEME, monacoThemeSettings);
    };

    const checkMonacoLoaded = () => {
      if (window.monaco) {
        initializeMonaco();
      } else {
        // Wait for Monaco in order to apply the theme until Monaco is loaded
        setTimeout(checkMonacoLoaded, ExperimentEditorConstants.TIMEOUT_TO_MONACO_LOAD);
      }
    };

    // Start checking if Monaco is loaded
    checkMonacoLoaded();
  }, [isDarkMode]);

  const HandleEditorChange = (value) => {
    setExperiment(value);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (monaco) {
        const errors = monaco.editor.getModelMarkers({ owner: ExperimentEditorConstants.ERROR_OWNER });
        const filteredErrors = errors.filter((item) => {
          return item.severity >= ExperimentEditorConstants.ERROR_SEVERITY_LEVEL;
        });

        setErrorsExist(filteredErrors.length !== 0);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [monaco]);

  const EditorMount = (editor, monaco) => {
    // set the editor so that we can overwrite it's value later when we load in a file
    setEditor(editor);
    setMonaco(monaco);
    // create the editor model for the data. This requires us to give a "URI" to the file
    // but we aren't using it so I gave it a clearly incorrect value
    const modelUri = monaco.Uri.parse(ExperimentEditorConstants.FAKE_URI);
    const model = monaco.editor.createModel(experiment, FileType.JSON, modelUri);

    editor.setModel(model);
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions(labToggle ? labsDiagnosticOptions : airDiagnosticOptions);
  };

  const updateLabToggle = (value: boolean) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions(value ? labsDiagnosticOptions : airDiagnosticOptions);
    setLabToggle(value);
  };

  React.useEffect(() => {
    // if the stack was flagged as "dirty" then the experiment value was overwritten and we need to re-set the editors value
    if (updateExperiment) {
      editor.setValue(experiment);
      setUpdateExperiment(false);
    }
  }, [updateExperiment]);

  // buildLeftNavMenuButtons : a local function for constructing the file items on the left of the commandbar
  const buildLeftNavMenuItems = () => {
    // toggleSchema : button for creating a "new" file. Basically just clears out your experiment data.

    const subMenuProps: IContextualMenuProps = {
      items: [
        {
          key: t('air', { ns: NS.DEFAULT }),
          text: t('air', { ns: NS.DEFAULT }),
          iconProps: { iconName: NavigationIcon[Navigation.AIR.CREATE_EXPERIMENT] },
          onClick: () => {
            updateLabToggle(false);
            setExperimentEditorLabsToggle(false);
          },
        },
        {
          key: t('labs', { ns: NS.DEFAULT }),
          text: t('labs', { ns: NS.DEFAULT }),
          iconProps: { iconName: NavigationIcon[Navigation.LABS.HOME] },
          onClick: () => {
            updateLabToggle(true);
            setExperimentEditorLabsToggle(true);
          },
        },
      ],
      // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
      // shouldFocusOnMount: false
    };

    const schema: IOverflowSetItemProps = {
      key: t('schema', { ns: NS.DEFAULT }),
      onRender: () => (
        <CommandBarButton
          text={
            t('schema', { ns: NS.DEFAULT }) + ' : ' + (labToggle ? t('labs', { ns: NS.DEFAULT }) : t('air', { ns: NS.DEFAULT }))
          }
          disabled={isPartnerMode}
          ariaLabel={t('schema', { ns: NS.DEFAULT })}
          title={t('schema', { ns: NS.DEFAULT }) + ':' + (labToggle ? t('labs', { ns: NS.DEFAULT }) : t('air', { ns: NS.DEFAULT }))}
          menuProps={subMenuProps}
        />
      ),
    };

    _switcherItems.push(schema);

    return _switcherItems;
  };

  // If the experiment has been updated set the saved flag to false, unless we loaded a template
  React.useEffect(() => {
    setExperimentSaved(false);
  }, [experiment]);

  const isStringEmpty = (toCheck: string) => {
    return !toCheck || /^\s*$/.test(toCheck);
  };

  // Loads local JSON file.
  const LocalLoad = async () => {
    setLoadingExperiment(true);
    const file = await SelectFile(setFileHandle);
    const loadResults = await LoadFile(file);

    setFileName(file.name);
    setExperiment(loadResults);
    setUpdateExperiment(true);
    setExperimentSaved(true);
    setLoadingExperiment(false);
  };

  // Confirms the action in the case where a experiment wasn't saved before making a new experiment, or loading a template
  const confirmedAction = () => {
    if (confirmationAction === ConfirmationAction.New) {
      setExperiment('');
      setUpdateExperiment(true);

      // set the fileHandle to null so that the user doesn't overwrite their previous save with a new file.
      setFileName(t('untitled', { ns: NS.EDITOR }));
      setFileHandle(null);
    } else if (confirmationAction === ConfirmationAction.LocalFile) {
      LocalLoad();
    }

    setConfirmationAction(ConfirmationAction.None);
    toggleShowConfirm();
  };

  const LoadTemplate = async (template: AssetNode) => {
    setLoadingExperiment(true);

    // Load the template from the new asset database using id
    setFileName(template.name);

    await assetsRequestService.getContent(template.contentIds[template.contentIds.length - 1]).then((data) => {
      setExperiment(JSON.stringify(data.data, null, 4));
      setUpdateExperiment(true);
      setExperimentSaved(true);
    });

    setLoadingExperiment(false);
  };

  // buildRightNavMenuItems : a local function for constructing the file navigation menu buttons
  const buildRightNavMenuItems = () => {
    // newButton : button for creating a "new" file. Basically just clears out your experiment data.
    const newButton: ICommandBarItemProps = {
      key: 'new-menu',
      text: t('new', { ns: NS.COMMON }),
      iconProps: { iconName: IconNames.PAGE_ADD },
      submenuIconProps: {
        iconName: IconNames.CHEVRON_DOWN,
      },
      subMenuProps: {
        items: [
          {
            key: 'new-json-file-item',
            text: t('new-json-file', { ns: NS.EDITOR }),
            iconProps: { iconName: IconNames.PAGE_ADD },
            onClick: () => {
              if (isExperimentSaved || isStringEmpty(experiment)) {
                setExperiment('');
                setUpdateExperiment(true);

                // set the fileHandle to null so that the user doesn't overwrite their previous save with a new file.
                setFileName(t('untitled', { ns: NS.EDITOR }));
                setFileHandle(null);
              } else {
                setConfirmationAction(ConfirmationAction.New);
                toggleShowConfirm();
              }
            },
          },
          {
            key: 'duplicate-current-file-item',
            text: t('duplicate-current-file', { ns: NS.EDITOR }),
            iconProps: { iconName: IconNames.PAGE_ADD },
            onClick: () => {
              const newName = `${t('copy-of', { ns: NS.EDITOR })} ${fileName}`;

              setFileName(newName);
              setFileHandle(null);
            },
          },
        ],
      },
    };

    // openButton : opens a file inside of the editor
    const openButton: ICommandBarItemProps = {
      key: 'open-menu',
      text: t('open', { ns: NS.COMMON }),
      iconProps: { iconName: IconNames.FABRIC_OPEN_FOLDER_HORIZONTAL },
      subMenuProps: {
        items: [
          {
            key: 'from-local-file-item',
            text: t('from-local-file', { ns: NS.EDITOR }),
            iconProps: { iconName: IconNames.FABRIC_OPEN_FOLDER_HORIZONTAL },
            onClick: () => {
              if (isExperimentSaved || isStringEmpty(experiment)) {
                LocalLoad();
              } else {
                setConfirmationAction(ConfirmationAction.LocalFile);
                toggleShowConfirm();
              }
            },
          },
        ],
      },
    };

    // saveButton : saves a file to the currently set fileHandle location.
    const saveButton: ICommandBarItemProps = {
      key: 'save-menu',
      text: t('save', { ns: NS.COMMON }),
      iconProps: { iconName: IconNames.SAVE },
      subMenuProps: {
        items: [
          {
            key: 'save-item',
            text: t('save', { ns: NS.COMMON }),
            iconProps: { iconName: IconNames.SAVE },
            disabled: !fileHandle,
            onClick: () => {
              const save = async () => {
                setExperimentSaved(true);
                await SaveFile(experiment, fileHandle, setFileHandle);
                return false;
              };

              save();
            },
          },
          {
            key: 'save-as-item',
            text: t('save-as', { ns: NS.COMMON }),
            iconProps: { iconName: IconNames.SAVE_AS },
            onClick: () => {
              const saveAs = async () => {
                setExperimentSaved(true);
                await SaveFileAs(experiment, setFileHandle);
                return false;
              };

              saveAs();
            },
          },
        ],
      },
    };

    // saveAsButton : creates a filehandle, selects a location, and then saves a file to it.
    const publishButton: ICommandBarItemProps = {
      key: t('queue', { ns: NS.EDITOR }),
      text: t('queue', { ns: NS.EDITOR }),
      disabled: isStringEmpty(experiment) || errorsExist,
      ariaLabel: t('queue', { ns: NS.EDITOR }),
      iconOnly: false,
      iconProps: { iconName: IconNames.QUEUE },
      title: t('queue', { ns: NS.EDITOR }),
      onClick: () => {
        toggleHidePublish();
      },
    };

    const previewButton: ICommandBarItemProps = {
      key: t('preview', { ns: NS.EDITOR }),
      text: t('preview', { ns: NS.EDITOR }),
      disabled: isStringEmpty(experiment) || errorsExist,
      ariaLabel: t('preview', { ns: NS.EDITOR }),
      iconOnly: false,
      iconProps: { iconName: IconNames.PREVIEW },
      title: t('preview', { ns: NS.EDITOR }),
      onClick: () => {
        toggleHidePreview();
      },
    };

    _menuItems.push(newButton, openButton, saveButton, publishButton, previewButton);

    return _menuItems;
  };

  const filterItems: IOverflowSetItemProps[] = buildLeftNavMenuItems();
  const farItems: ICommandBarItemProps[] = buildRightNavMenuItems();

  const subTitle = fileHandle ? fileHandle.name : fileName;
  const experimentName: ICommandBarItemProps[] = [
    {
      key: 'title',
      onRender: () => <div className={styles['subtitle']}>{t(subTitle, { ns: NS.COMMON })}</div>,
    },
  ];

  const title = `${t('experiment-editor', { ns: NS.TITLES })}${fileName ? ` - ${fileName}` : ''}`;

  return (
    <ExperimentEditorBetaTemplate
      experimentName={experimentName}
      farItems={farItems}
      filterItems={filterItems}
      hideConfirmModal={toggleShowConfirm}
      hidePublish={hidePublish}
      hidePreview={hidePreview}
      isConfirmModalOpen={showConfirm}
      labId={labId ? parseInt(labId) : -1}
      labToggle={labToggle}
      loadTemplate={LoadTemplate}
      loadingExperiment={isExperimentLoading}
      toggleHidePublish={toggleHidePublish}
      toggleHidePreview={toggleHidePreview}
      splitPanelConfig={splitPanelConfig}
      confirmedAction={confirmedAction}
      editorMount={EditorMount}
      handleEditorChange={HandleEditorChange}
    />
  );
};

const ExperimentEditorBetaViewController = observer(ExperimentEditorBetaViewControllerFC);

export default ExperimentEditorBetaViewController;

import React from 'react';
import { CommandBarButton } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { extractString } from '@/utils/Helpers';

// This component will be changed to MVVM in future

interface GroupByLayoutButtonProps {
  tableData: any[];
  tableColumns: any[];
  setData: (data: any[]) => void;
  setTableGroups: (groups: any[]) => void;
  pageKey: string;
  filtersChanged?: boolean;
  defaultGroupByColumn?: string;
}

const GroupByRenderer = ({
  tableData,
  tableColumns,
  setData,
  setTableGroups,
  pageKey,
  filtersChanged = false,
  defaultGroupByColumn = '',
}: GroupByLayoutButtonProps): ICommandBarItemProps => {
  // Store Const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { groupByColumn, setGroupByColumn } = appSettingsStore;

  // State Const
  const [initialGroupByDone, setInitialGroupByDone] = React.useState<boolean>(false);

  // Other Const
  const groupByNoneKey = PageCommandBar.GROUPBY_NONE;
  const groupByNoneText = t(groupByNoneKey, { ns: NS.TABLE });
  const storedGroupByColumn = groupByColumn[pageKey as string] || defaultGroupByColumn || groupByNoneKey;
  const groupByColumnText = t(groupByColumn[pageKey as string], { ns: NS.TABLE }) || t(defaultGroupByColumn, { ns: NS.TABLE });

  // This is called only once after rendering, to group by the data based on the stored group by value.
  React.useEffect(() => {
    if (!initialGroupByDone && storedGroupByColumn) {
      handleGroupBySelected(storedGroupByColumn);
      setInitialGroupByDone(true);
    }
  }, [storedGroupByColumn, tableData]);

  // This is called whenever there is a filter change in the page (It may be a selection or deselection)
  React.useEffect(() => {
    if (storedGroupByColumn) {
      handleGroupBySelected(storedGroupByColumn);
    }
  }, [filtersChanged]);

  const handleGroupBySelected = (groupByColumnKey: string): void => {
    const { returnData, groups } = getGroupedTableData(tableData, groupByColumnKey, tableColumns);

    setData(returnData);
    setTableGroups(groups);
    setGroupByColumn(pageKey, groupByColumnKey);
  };

  const createGroup = (name: string, startIndex: number, count: number) => {
    const group = {
      name: name,
      key: name,
      startIndex: startIndex,
      count: count,
      level: 0,
    };

    return group;
  };

  // To get the unique group by data
  const getGroupParents = (tableData: any[], fieldName: string) => {
    const uniqueValues: string[] = Array.from(new Set(tableData.map((obj) => extractString(obj[fieldName as string]).trim())));
    const groupParents = uniqueValues.sort().map((value) => ({ [fieldName]: value.trim() }));

    return groupParents;
  };

  const getSortedData = (tableData: any[], fieldName: string): any[] => {
    const sortedData = [...tableData].sort((a, b) => {
      const aValue = extractString(a[fieldName as string]);
      const bValue = extractString(b[fieldName as string]);

      if (aValue === null || bValue === null) {
        return aValue === null ? 1 : -1;
      }

      return aValue.localeCompare(bValue);
    });

    return sortedData;
  };

  const generateGroups = (tableData: any[], parentGroupData: any[], fieldName: string): any[] => {
    if (tableData.length <= 0 || parentGroupData.length <= 0) {
      return [];
    }

    const groups = [];
    let currentValue = '';

    // Create a hash map to count the occurrences of each group
    const groupCounts = tableData.reduce((counts, item) => {
      const value = extractString(item[fieldName as string]).trim();

      counts[value as string] = (counts[value as string] || 0) + 1;

      return counts;
    }, {});

    for (let i = 0; i < parentGroupData.length; i++) {
      currentValue = parentGroupData[i as number][fieldName as string].trim();

      const currentIdx = tableData.findIndex((item) => extractString(item[fieldName as string]) === currentValue);
      const currentGroupCount = groupCounts[currentValue as string];

      groups.push(createGroup(currentValue, currentIdx, currentGroupCount));
    }

    return groups;
  };

  const getGroupedTableData = (
    tableData: any[],
    groupByColumnKey: string,
    tableColumns: any[],
  ): {
    returnData: any[];
    groups: any;
  } => {
    const matchingColumn = tableColumns.find((column: any) => column.key === groupByColumnKey);

    if (matchingColumn) {
      const fieldName = matchingColumn.fieldName;
      const sortedTableData = getSortedData(tableData, fieldName);

      const groupParents = getGroupParents(sortedTableData, fieldName);
      const groups = generateGroups(sortedTableData, groupParents, fieldName);

      return { returnData: sortedTableData, groups };
    } else {
      // When there is no matching column or group by none is selected
      const groups = null;
      const sortedTableData = getSortedData(tableData, '');

      return { returnData: sortedTableData, groups };
    }
  };

  const groupByNoneItem: ICommandBarItemProps = {
    key: groupByNoneKey,
    text: groupByNoneText,
    onClick: () => handleGroupBySelected(groupByNoneKey),
  };

  const groupByProps = (): ICommandBarItemProps[] => {
    return tableColumns
      .filter((item) => item.isGroupBy)
      .map((item) => {
        const groupByName = t(item.name, { ns: NS.TABLE });

        return {
          key: item.key,
          text: groupByName,
          onClick: () => handleGroupBySelected(item.key),
        };
      });
  };

  const groupByButton: ICommandBarItemProps = {
    key: 'group-by',
    onRender: () => (
      <CommandBarButton
        text={groupByColumnText || groupByNoneText}
        iconProps={{ iconName: SystemIcons.GROUP_LIST }}
        menuProps={{ items: [groupByNoneItem, ...groupByProps()] }}
        role="menuitem"
      />
    ),
  };

  return groupByButton;
};

export default GroupByRenderer;

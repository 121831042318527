import React from 'react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';

const tooltip: Partial<ITooltipHostStyles> = {
  root: {
    display: 'inline-block',
  },
};

const styles = { tooltip };

export default styles;
